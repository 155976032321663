import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import Sidebar from "./sidebar";
import { useParams } from "react-router-dom";
import { Container } from 'react-bootstrap';
import Dashboardheader from "./dashboardheader";
import { getStaffDutyAction } from '../../Action/admin.action';
import { decryptData } from './decrypt';

// Function to transform schedule data into FullCalendar events
const transformScheduleDataToEvents = (schedule) => {
  return schedule.flatMap((appointment) => {
    const { dutyDate, dutyTime } = appointment;
    let dutyTimes = [];

    // Parse dutyTime (JSON string)
    try {
      dutyTimes = JSON.parse(dutyTime);
    } catch (e) {
      console.error("Error parsing dutyTime:", e);
      return [];
    }

    // Create an event for each time slot
    return dutyTimes.map(({ from, to }) => ({
      start: `${dutyDate}T${from}`,
      end: `${dutyDate}T${to}`,
      fromTime: from,
      toTime: to,
    }));
  });
};

const CalendarComponent = () => {
  let { id } = useParams();
  const [staffDuty, setStaffDuty] = useState([]);
  const [show, setShow] = useState(false);
  const [showDateModal, setShowDateModal] = useState(false);
  const [eventDetails, setEventDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  // Transform the staff duty data into events for the calendar
  const events = transformScheduleDataToEvents(staffDuty);
  useEffect(() => {
    getStaffDutyAPI();
  }, []);

  const getStaffDutyAPI = async () => {
    let res = await getStaffDutyAction({ id: id });
    setIsLoading(false);
    if (res.success) {
      setStaffDuty(decryptData(res.data));
    }
  };

  const handleEventClick = (clickInfo) => {
    setEventDetails(clickInfo.event);
    setShow(true);
  };

  const determineBackgroundColor = (eventDate) => {
    const today = new Date();
    if (eventDate < today) {
      return { backgroundColor: 'rgb(234, 67, 53)', color: '#ffff', borderRadius: '3px', padding: '4px' };
    } else if (eventDate.toDateString() === today.toDateString()) {
      return { backgroundColor: 'rgb(11, 128, 67)', color: '#ffff', borderRadius: '3px', padding: '4px' };
    } else {
      return { backgroundColor: 'rgb(11, 128, 67)', color: '#ffff', borderRadius: '3px', padding: '4px' };
    }
  };

  const renderEventContent = (eventInfo) => {
    const backgroundColor = determineBackgroundColor(new Date(eventInfo.event.start));
    return (
      <div style={backgroundColor}>
        <i>{eventInfo.event.extendedProps.fromTime} - {eventInfo.event.extendedProps.toTime}</i>
      </div>
    );
  };

  return (
    <>
      <Dashboardheader />
      <div className="wrapper">
        <Sidebar />
        <div id="content" className="calendar">
          <Container fluid className="p-4">
            <h1>User Duties</h1>
            <div className="calendar-main">
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                  left: 'prev,next today',
                  center: 'title',
                }}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                eventClick={handleEventClick}
                initialView="dayGridMonth"
                events={events}
                eventContent={renderEventContent}
              />
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default CalendarComponent;
