import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "../componentCss/webform.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import Dashboardheader from "./dashboardheader";
import Sidebar from "./sidebar";
import "../componentCss/sidebar.css";
import {
  getFormDetailsAction,
  insertFormDataAction,
  getFormDataByMasterAction
} from "../../Action/admin.action";
import toast, { Toaster } from 'react-hot-toast';
import "react-toastify/dist/ReactToastify.css";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import config from "../../config/config";
import Sketch from "./Sketch";
import { decryptData } from "./decrypt";
import Select from "react-select";

const Webform = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [formDetails, setFormDetails] = useState([]);
  const [masterDataDropDown, setMasterDataDropDown] = useState([]);
  const [subFormDetails, setSubFormDetails] = useState([]);
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    getFormDetailsAPI();
  }, []);


  let getFormDataByMasterAPI = async (dropdownFormId,dropdownFieldId) => {
    
    let res = await getFormDataByMasterAction({ dropdownFormId: dropdownFormId,dropdownFieldId:dropdownFieldId });
    if (res.success) {
      setMasterDataDropDown(decryptData(res.data));
    }
  };
  

  let getFormDetailsAPI = async () => {
    let res = await getFormDetailsAction({ id: id });
    if (res.success) {
      setFormDetails(decryptData(res.data));
      let data = decryptData(res.data)
      getFormDataByMasterAPI(data[0]?.dropdownFormId,data[0].dropdownFieldId)

    }
  };
  const handleChange = async (e, item) => {
    let tempFormDetails = [...formDetails]
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormValues({ ...formValues, [name]: newValue });
    if (type == "radio") {
      if (
        item.dropdownFormId > 0 &&
        (value.trim() == "Yes" ||
          value.trim() == "YES" ||
          value.trim() == "yes")
      ) {
        let res = await getFormDetailsAction({ id: item.dropdownFormId });
        if (res.success) {
          let resForm = tempFormDetails.find(data => data.id === item.id);
          resForm.dropDownFormData = res.data
          setFormDetails(tempFormDetails)
          setSubFormDetails(res.data);
        }
      } else {
        let resForm = tempFormDetails.find(data => data.id === item.id);
        resForm.dropDownFormData = ''
        setFormDetails(tempFormDetails)
        setSubFormDetails([]);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    formValues.formId = id;
    let res = await insertFormDataAction(formValues);
    if (res.success) {
      toast.success(res.msg);
      setInterval(() => {
        navigate(`${config.baseUrl}webforms`);
      }, 2000);
    } else {
      toast.error(res.msg);
    }
  };

  return (
    <>
      <Dashboardheader />
      <Toaster />
      <div className="wrapper">
        <Sidebar />
        <div id="content">
          <Container fluid className="px-5 pt-5" >
            <Row>
              <Col lg={12} md={12} sm={12} className="">
                <div className="innerhead mb-4">
                  <h5 className="headtitle">
                    {formDetails.length > 0 ? formDetails[0].formName : ""}
                  </h5>

                  <Link to={`${config.baseUrl}editWebForm/${id}`}>
                    <span>Edit Form </span>
                  </Link>

                  <Link to={`${config.baseUrl}webforms`}>
                    <span style={{ float: "right" }}>
                      Back <BsArrowLeftCircleFill />
                    </span>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
          <Container className="p-4">
            <Row>
              <Col lg={12} md={12} sm={12} className="">
                <div className="formDiv">
                  <Form onSubmit={handleSubmit} >
                    <Row className="mb-4 d-flex justify-content-center">
                      {formDetails.length > 0
                        ? formDetails.map((item) => (
                          <div
                            className={
                              item.fieldTypeId == 17
                                ? "col-md-12 mt-3"
                                : "col-md-6 mt-3"
                            }
                          >
                            {item.fieldTypeId === 17 && (
                              <Row>
                                <Col lg={12} md={12} sm={12}>
                                  <div
                                    style={{
                                      background:
                                        "linear-gradient(268deg, #4B91CE 0%, #5A73B5 100%)",
                                      color: "white",
                                      padding: "10px",
                                      borderRadius: "5px",
                                      textAlign: "center",
                                      fontSize: "16px",
                                      fontWeight: "bold",
                                      textShadow:
                                        "2px 1px 4px rgba(0, 0, 0, 0.25)",
                                    }}
                                  >
                                    <span>{item.fieldName}</span>
                                  </div>
                                </Col>
                              </Row>
                            )}
                            <Form.Group as={Col} controlId="formGridEmail">
                              <Form.Label>
                                {item.fieldtype == "btn" ||
                                  item.fieldTypeId == 17
                                  ? ""
                                  : item.fieldName}
                              </Form.Label>{" "}
                              <br />
                              {item.fieldtype == "select" ? (
                                <select
                                  className="form-control"
                                  id={item.id}
                                  name={item.id}
                                  onChange={handleChange}
                                >
                                  <option value={0}>Select Department</option>
                                  {item.dropdownValue != null
                                    ? JSON.parse(item.dropdownValue).map(
                                      (itemDropdown) => (
                                        <>
                                          <option
                                            value={itemDropdown.fieldValue}
                                          >
                                            {itemDropdown.fieldValue}
                                          </option>
                                        </>
                                      )
                                    )
                                    : ""}
                                </select>
                              ) : item.fieldtype == "radio" ||
                                item.fieldtype == "checkbox" ? (
                                <>
                                  {item.radioCheckboxValue &&
                                    JSON.parse(item.radioCheckboxValue).map(
                                      (radioData) => (
                                        <>
                                          <input
                                            onChange={(e) =>
                                              handleChange(e, item)
                                            }
                                            value={radioData}
                                            type={item.fieldtype}
                                            name={item.id}
                                          />{" "}
                                          {radioData} <br />
                                        </>
                                      )
                                    )
                                  }
                                  {item.dropDownFormData ? <div
                                    style={{ border: "1px solid grey ", padding: "15px" }}
                                  >
                                    <Row className="mb-4">
                                      {subFormDetails.length > 0
                                        ? subFormDetails.map((item) => (
                                          <div
                                            className={
                                              item.fieldTypeId == 17
                                                ? "col-md-12 mt-3"
                                                : "col-md-6 mt-3"
                                            }
                                          >
                                            {item.fieldTypeId === 17 && (
                                              <Row>
                                                <Col lg={12} md={12} sm={12}>
                                                  <div
                                                    style={{
                                                      background:
                                                        "linear-gradient(268deg, #4B91CE 0%, #5A73B5 100%)",
                                                      color: "white",
                                                      padding: "10px",
                                                      borderRadius: "5px",
                                                      textAlign: "center",
                                                      fontSize: "16px",
                                                      fontWeight: "bold",
                                                      textShadow:
                                                        "2px 1px 4px rgba(0, 0, 0, 0.25)",
                                                    }}
                                                  >
                                                    <span>{item.fieldName}</span>
                                                  </div>
                                                </Col>
                                              </Row>
                                            )}
                                            <Form.Group
                                              as={Col}
                                              controlId="formGridEmail"
                                            >
                                              <Form.Label>
                                                {item.fieldtype == "btn" ||
                                                  item.fieldTypeId == 17
                                                  ? ""
                                                  : item.fieldName}
                                              </Form.Label>{" "}
                                              <br />
                                              {item.fieldtype == "select" ? (
                                                <select
                                                  className="form-control"
                                                  id={item.id}
                                                  name={item.id}
                                                  onChange={handleChange}
                                                >
                                                  <option value={0}>
                                                    Select Department
                                                  </option>
                                                  {item.dropdownValue != null
                                                    ? JSON.parse(item.dropdownValue).map(
                                                      (itemDropdown) => (
                                                        <>
                                                          <option
                                                            value={
                                                              itemDropdown.fieldValue
                                                            }
                                                          >
                                                            {itemDropdown.fieldValue}
                                                          </option>
                                                        </>
                                                      )
                                                    )
                                                    : ""}
                                                </select>
                                              ) : item.fieldtype == "radio" ||
                                                item.fieldtype == "checkbox" ? (
                                                JSON.parse(item.radioCheckboxValue).map(
                                                  (radioData) => (
                                                    <>
                                                      <input
                                                        onChange={(e) =>
                                                          handleChange(e, item)
                                                        }
                                                        value={radioData}
                                                        type={item.fieldtype}
                                                        name={item.id}
                                                      />{" "}
                                                      {radioData} <br />
                                                    </>
                                                  )
                                                )
                                              ) : item.fieldtype == "selectManual" ? (
                                                <>
                                                  <select
                                                    className="form-control"
                                                    name={item.id}
                                                    onChange={handleChange}
                                                  >
                                                    <option>-Select-</option>
                                                    {JSON.parse(
                                                      item.radioCheckboxValue
                                                    ).map((radioData) => (
                                                      <>
                                                        <option value={radioData}>
                                                          {radioData}
                                                        </option>
                                                      </>
                                                    ))}
                                                  </select>
                                                </>
                                              ) : item.fieldtype == "btn" ? (
                                                <>
                                                  <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                  >
                                                    {item.fieldName}
                                                  </button>
                                                </>
                                              ) : item.fieldTypeId == 18 ? (
                                                <Sketch />
                                              ) : item.fieldTypeId != 17 ? (
                                                <Form.Control
                                                  onChange={handleChange}
                                                  type={item.fieldtype}
                                                  name={item.id}
                                                  placeholder={`Enter ${item.fieldName}`}
                                                />
                                              ) : (
                                                ""
                                              )}
                                            </Form.Group>
                                          </div>
                                        ))
                                        : ""}
                                    </Row></div> : ""}
                                </>
                              ) : item.fieldtype == "selectManual" ? (
                                <>
                                  {JSON.parse(item.radioCheckboxValue).length > 0 ?
                                    <select
                                      className="form-control"
                                      name={item.id}
                                      onChange={handleChange}
                                    >
                                      <option>-Select-</option>
                                      {JSON.parse(item.radioCheckboxValue).map(
                                        (radioData) => (
                                          <>
                                            <option value={radioData}>
                                              {radioData}
                                            </option>
                                          </>
                                        )
                                      )}
                                    </select>
                                    : ""}
                                </>
                              ) : item.fieldtype == "btn" ? (
                                <>
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                  >
                                    {item.fieldName}
                                  </button>
                                </>
                              ) : item.fieldTypeId == 18 ? (
                                <div className="col-md-12 mt-3">
                                  <Sketch />
                                </div>
                              ) : 
                              item.fieldTypeId == 20 ?(
                                <Select
                                id={item.id}
                                name={item.id}
                                options={masterDataDropDown.map((itemDropdown) => ({
                                  value: itemDropdown[item.dropdownFieldId],
                                  label: itemDropdown[item.dropdownFieldId],
                                }))}
                                onChange={(selectedOption) =>
                                  handleChange(
                                    { target: { name: item.id, value: selectedOption?.value || "" } },
                                    item
                                  )
                                }
                                placeholder="-Select an Option-"
                                isClearable
                              />
                              ):
                              
                              
                              item.fieldTypeId != 17 ? (
                                <Form.Control
                                  onChange={handleChange}
                                  type={item.fieldtype}
                                  name={item.id}
                                  placeholder={`Enter ${item.fieldName}`}
                                />
                              ) : (
                                ""
                              )}
                            </Form.Group>
                          </div>
                        ))
                        : ""}
                    </Row>
                    <br />
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};
export default Webform;
