import React, { useState } from 'react'
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import '../componentCss/webform.css';
import { useNavigate } from "react-router-dom";
import Dashboardheader from './dashboardheader';
import Sidebar from './sidebar';
import '../componentCss/sidebar.css';
import { saveMasterDataAction } from '../../Action/admin.action';
// import { ToastContainer, toast } from 'react-toastify';
import toast from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';
import { BsArrowLeftCircleFill, BsFillTrash3Fill } from "react-icons/bs";
import config from '../../config/config';
import { Link } from "react-router-dom";
let fieldArr = [];

const Webform = () => {
    const navigate = useNavigate();
    const [formData, setForm] = useState({ tableName: '' })
    const [formValues, setFormValues] = useState([{ fieldName: "" }])
    const [rowIndex, setRowIndex] = useState(0)

    let handleChangeForm = (e) => {
        let { name, value } = e.target
        setForm((old) => {
            return { ...old, [name]: value }
        })
    }

    let handleChange = (i, e) => {
        let newFormValues = [...formValues];
        if (e.target.value != '' && isFirstCharacterNotNumber(e.target.value) == false) {
            toast.error("First character should not any number.");
            return;
        }
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
    }

    let addFormFields = () => {
        fieldArr.push([])
        setFormValues([...formValues, { fieldName: "" }])
        setRowIndex(rowIndex + 1);
    }

    function isFirstCharacterNotNumber(str) {
        return isNaN(str.charAt(0));
    }

    let handleSubmit = async (event) => {
        event.preventDefault();

        if (!formData.tableName) {
            toast.error("Table name required.");
            return;
        }

        if (isFirstCharacterNotNumber(formData.tableName) == false) {
            toast.error("First character should not any number.");
            return;
        }

        let isAllFieldsFill = 0;
        for (let i = 0; i < formValues.length; i++) {
            if (!formValues[i].fieldName) {
                isAllFieldsFill = 1
                break
            }
        }

        if (isAllFieldsFill) {
            toast.error('All fields are required.');
            return;
        }

        let data = {
            'tableName': formData.tableName,
            'fieldsData': JSON.stringify(formValues)
        }

        let res = await saveMasterDataAction(data);
        if (res.success) {
            toast.success(res.msg);
            setTimeout(() => {
                navigate(`${config.baseUrl}master-data`);
            }, 2000);
        } else {
            toast.error(res.msg);
        }
    }

    let removeFormFields = (i) => {
        setRowIndex(rowIndex - 1);
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    }

    return (
        <>
            <Dashboardheader />
            {/* <ToastContainer /> */}
            <div className="wrapper">

                <Sidebar />
                <div id="content">
                    <Container fluid className="p-4">
                        <Row>
                            <Col lg={12} md={12} sm={12} className="">
                                <div className="innerhead mb-4">
                                    <h5 className='headtitle'>Add New Master Table</h5>
                                </div>
                                <Link to={`${config.baseUrl}master-data`} >
                                    <span style={{ float: 'right' }}>Back <BsArrowLeftCircleFill /></span>
                                </Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} md={12} sm={12} className="">
                                <div className='formDiv'>
                                    <Form>
                                        <Row className="mb-4">
                                            <Form.Group as={Col} controlId="formGridEmail">
                                                <Form.Label>Table name</Form.Label>
                                                <input className='form-control' type="text" name='tableName' onChange={handleChangeForm} placeholder="Enter table name" onKeyPress={(event) => {
                                                    if (!/^[a-zA-Z0-9]*$/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }} />
                                            </Form.Group>
                                        </Row>

                                    </Form>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12} md={12} sm={12} className="mt-0">
                                <div className='maintable table-responsive'>
                                    <h5 className=''>Master Table Fields</h5>
                                    <form onSubmit={handleSubmit} className='mt-4'>
                                        {formValues.map((element, index) => (
                                            <div className="form-inline mt-2" key={index}>
                                                <div className='row'>

                                                    <div className='col-md-1'>
                                                        {index == 0 ?
                                                            <> <span>No.</span> <br /></>
                                                            : ""}
                                                        <label>{index + 1}</label>
                                                    </div>

                                                    <div className='col-md-6'>
                                                        {index == 0 ?
                                                            <> <span>Field Name</span> <br /></>
                                                            : ""}
                                                        <input type="text" className='form-control' name="fieldName" value={element.fieldName || ""} onKeyPress={(event) => {
                                                            if (!/^[a-zA-Z0-9]*$/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }} onChange={e => handleChange(index, e)} />
                                                    </div>

                                                    <div className='col-md-1'>
                                                        {
                                                            index ?
                                                                <BsFillTrash3Fill onClick={() => removeFormFields(index)} />
                                                                : null
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                        ))}
                                        <br />
                                        <div className="button-section">
                                            <Button variant="secondary-btn3" onClick={() => addFormFields()} className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6'>Add field</Button> &nbsp;&nbsp;&nbsp;&nbsp;
                                            <Button variant="secondary-btn3" type='submit' className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6'>Submit</Button>
                                        </div>
                                    </form>
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </div >

        </>
    )
}

export default Webform;
