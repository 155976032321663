import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import "../componentCss/webform.css";
import { useParams } from "react-router-dom";
import Dashboardheader from "./dashboardheader";
import Sidebar from "./sidebar";
import "../componentCss/sidebar.css";
import {
  getFormDetailsAction,
  insertFormDataAction,
  insertFormDataActionForSubForm,
  getPatientDataAction,
  getDraftDataAction
} from "../../Action/admin.action";
import toast from 'react-hot-toast';
import "react-toastify/dist/ReactToastify.css";
import MyKonvaComponent from "./ModalComponents/MyKonvaComponent";
import propertise from "../../config/properties";
import { decryptData } from "./decrypt";
import config from "../../config/config";
let MRNNumber = "MRN" + (Math.floor(Math.random() * 900000) + 100000);
let patientMrnNumber = localStorage.getItem('patientMrnNumber');
const Webform = () => {
  let { formId, id, patientId } = useParams();
  let [formValues, setFormValues] = useState({});
  const [draftData, setDraftData] = useState([]);
  const [draftDataList, setDraftDataList] = useState([]);
  const [formDetails, setFormDetails] = useState([]);
  const [subFormValues, setSubFormValues] = useState([]);
  const [subFormDetails, setSubFormDetails] = useState([]);
  const [formPreviewValues, setFormPreviewValues] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const [formDetailIdForForm, setFormDetailsId] = useState('')
  const [patientDetails, setPatientDetails] = useState({ emrNumber: '', mrnNumber: MRNNumber });
  const [stageRefs, setStageRefs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const refsArray = formDetails.map(() => React.createRef());
    setStageRefs(refsArray);
  }, [formDetails]);

  useEffect(() => {
    getFormDetailsAPI();
    getDraftDataAPI()

    if (patientId && patientId != 'undefined') {
      getPatientDataAPI()
    }

  }, []);

  let getDraftDataAPI = async () => {
    let res = await getDraftDataAction({ id: formId });
    if (res.success) {
      setDraftData(res.data[0]);
      setDraftDataList(res.data)
    }
  };

  let getPatientDataAPI = async () => {
    let res = await getPatientDataAction({ 'patientId': patientId, 'formId': propertise.patientAdmit });
    if (res.success) {
      let data = decryptData(res.data);
      setPatientDetails({
        ...patientDetails,
        ['emrNumber']: data.emrNumber,
        ['mrnNumber']: data.mrnNumber
      });
    }
  };

  let getFormDetailsAPI = async () => {
    let res = await getFormDetailsAction({ id: formId });
    if (res.success) {
      setFormDetails(decryptData(res.data));
    }
  };

  const handleChangeData = (e) => {
    const { name, value } = e.target;
    setPatientDetails({
      ...patientDetails,
      [name]: value,
    });
  };

  const handleChange = async (e, item) => {
    let tempFormDetails = [...formDetails];
    let subFormData = [...subFormDetails];
    let tempSubFormValues = [...subFormValues];

    const { name, value, type, checked, id } = e.target;

    let newValue = "";
    let newValue1 = "";
    if (type === "checkbox") {
      newValue = checked;
    } else if (type === "file") {
      newValue = e.target.files[0];
    } else if (name == "assignTo") {
      const [id, ...firstNameParts] = e.target.value.split("_");
      const firstName = firstNameParts.join("_");

      newValue = id;
      newValue1 = firstName;
    } else {
      newValue = value;
    }

    if (type == "radio") {
      if (
        item.dropdownFormId > 0 &&
        (value.trim() == "Yes" ||
          value.trim() == "YES" ||
          value.trim() == "yes")
      ) {
        tempSubFormValues.push({
          subFormId: item.dropdownFormId,
          id,
          formDetailId: item.id,
        });
        setFormDetailsId(item.id)
        setSubFormValues(tempSubFormValues);
        let res = await getFormDetailsAction({ id: item.dropdownFormId });
        if (res.success) {
          let resForm = tempFormDetails.find((data) => data.id === item.id);
          resForm.dropDownFormData = decryptData(res.data);
          let obj = decryptData(res.data);
          obj.dropdownFormId = item.dropdownFormId;
          setFormDetails(tempFormDetails);
          subFormData.push(obj);
          setSubFormDetails(subFormData);
        }
      } else {
        let resForm = tempFormDetails.find((data) => data.id === item.id);
        let getSubFormIndex = subFormDetails.findIndex(
          (data) => data.dropdownFormId === item.dropdownFormId
        );
        subFormDetails.splice(getSubFormIndex, 1);

        resForm.dropDownFormData = "";
        setFormDetails(tempFormDetails);
        setSubFormDetails(subFormDetails);
      }
    }
    if (draftDataList.length > 0) {
      setDraftData({ ...draftData, [name]: newValue })
    } else {
      setFormValues({ ...formValues, [name]: newValue });
    }

    setFormPreviewValues({
      ...formPreviewValues,
      [id]: newValue1 !== "" ? newValue1 : newValue,
    });
  };

  const handleChangeMRNNUmber = async (e, item) => {
    const { value } = e.target;
    setPatientDetails({
      ...patientDetails,
      ['mrnNumber']: value
    });
  };

  const handleChangeForSubForm = async (e, formId) => {
    const { name, value, type, checked, id } = e.target;
    let newValue = "";
    if (type === "checkbox") {
      newValue = checked;
    } else if (type === "file") {
      newValue = e.target.files[0];
    } else {
      newValue = value;
    }

    let getIndex = subFormValues.findIndex((data) => data.subFormId === formId);
    if (getIndex > -1) {
      const updatedForm = {
        ...subFormValues[getIndex],
        [name]: newValue,
        formDetailId: formDetailIdForForm,
        formId: formId,
        subFormId: formId,
      };
      const updatedSubFormValues = [...subFormValues];
      updatedSubFormValues[getIndex] = updatedForm;
      setSubFormValues(updatedSubFormValues);
      setFormPreviewValues({ ...formPreviewValues, [id]: newValue });
    }
  };

  const resetForm = async (e) => {
    e.preventDefault();
    setTimeout(() => {
      window.location.reload()
    },);
  }

  const handleSubmit = async (e, submitType, redirectionType = 0) => {
    e.preventDefault();
    setIsLoading(true)
    if (draftDataList.length > 0) {
      formValues = draftData;
    }

    formValues.formId = formId;
    formValues.referenceFormId = id;

    formValues.pid = patientId
    formValues.submitType = submitType
    formValues.mrnNumber = patientDetails?.mrnNumber
    formValues.emrNumber = patientDetails?.emrNumber
    formValues.isEdit = 0;

    let res = await insertFormDataAction({ formValues: formValues });
    if (res.success) {

      // Update sub form data
      if (subFormValues.length > 0) {
        await insertFormDataActionForSubForm({ subFormValues: subFormValues });
      }

      toast.success(res.msg);

      if (redirectionType == 1) {
        let patientid = res.data[0].id
        if (formId != propertise.patientAdmit) {
          patientid = patientId
        }
        setTimeout(() => {
          window.location.href = `${config.baseUrl}form-submission/${formDetails[0].actionFormId}/0/${patientid}`
        }, 2000);
      } else {
        if (formId == 4) {
          setTimeout(() => {
            window.location.href = `${config.baseUrl}staffDashboard`
          }, 2000);
        } else {
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      }
    } else {
      setIsLoading(false)
      toast.error(res.msg);
    }
  };

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const handleSave = (e, index) => {
    e.preventDefault();
    const stageRef = stageRefs[index].current;
    const uri = stageRef.toDataURL();
    const file = dataURLtoFile(uri, `${stageRef.attrs.name}.png`);
    if (file) {
      toast.success('Image Saved..')
      setFormValues({ ...formValues, [stageRef.attrs.name]: file });
      setFormPreviewValues({
        ...formPreviewValues,
        [stageRef.attrs.id]: file
      });
    }
  };

  const formPreview = (e, index) => {
    e.preventDefault();
    setShowPreview(true);
  };

  const handleCancel = () => {
    setShowPreview(false);
  };

  function FormPreview({ formData, onCancel, onSubmit }) {
    const [imageUrls, setImageUrls] = useState({});
    useEffect(() => {
      const newImageUrls = {};
      Object.entries(formData).forEach(([key, value]) => {
        if (value instanceof File) {
          newImageUrls[key] = URL.createObjectURL(value);
          return () => URL.revokeObjectURL(newImageUrls[key]);
        }
      });
      setImageUrls(newImageUrls);
    }, [formData]);

    const getDisplayValue = (key, value) => {
      if (value instanceof File) {
        if (!imageUrls[key]) {
          const url = URL.createObjectURL(value);
          setImageUrls((prevUrls) => ({
            ...prevUrls,
            [key]: url,
          }));
          return url;
        }
        return imageUrls[key];
      }
      return value;
    };


    return (
      <div className="form-preview-container">
        <h2 className="text-center mb-4">Form Preview</h2>

        <div className="card shadow p-3 mb-5 bg-white rounded">
          <ul className="list-group list-group-flush">
            {draftDataList.length > 0 ? (
              Object.entries(draftData).map(([key, value]) => (
                <li key={key} className="list-group-item d-flex justify-content-between align-items-center">
                  <strong>{key}:</strong>
                  {(value instanceof File) ? (
                    <div>
                      <img
                        src={getDisplayValue(key, value)}
                        alt="Preview"
                        className="img-thumbnail"
                        style={{ maxWidth: "200px", height: "auto" }}
                      />
                    </div>
                  ) : (
                    <span>{getDisplayValue(key, value)}</span>
                  )}
                </li>
              ))
            ) : (
              formDetails.map((data) => (
                formValues[data.id] && (
                  <li key={data.id} className="list-group-item d-flex justify-content-between align-items-center">
                    <strong>{data.fieldName}:</strong>
                    {(formValues[data.id] instanceof File) ? (
                      <div>
                        <img
                          src={getDisplayValue(data.id, formValues[data.id])}
                          alt="Preview"
                          className="img-thumbnail"
                          style={{ maxWidth: "200px", height: "auto" }}
                        />
                      </div>
                    ) : (
                      <span>{getDisplayValue(data.id, formValues[data.id])}</span>
                    )}
                  </li>
                )
              ))
            )}
          </ul>
        </div>

        <div className="button-container text-center">
          <button className="btn btn-secondary mt-3 me-2" onClick={onCancel}>
            Cancel
          </button>

          {formId == 4 &&
            <button disabled={isLoading == true ? true : false} className="btn btn-primary mt-3" onClick={(e) => handleSubmit(e, 1, 0)}>
              {isLoading == true ? 'Loading...' : 'Submit'}
            </button>
          }
          &nbsp;&nbsp;
          {formDetails[0].isCallToAction === 1 && (
            <button disabled={isLoading == true ? true : false} className="btn btn-primary mt-3" onClick={(e) => handleSubmit(e, 1, 1)}>
              {isLoading == true ? 'Loading...' : 'Submit & Redirect'}
            </button>
          )}
        </div>
      </div>
    );

  }

  return (
    <>
      <Dashboardheader />
      <div className="wrapper">
        <Sidebar />
        <div id="content">
          <Container fluid className="p-4">
            <Row>
              <Col lg={12} md={12} sm={12} className="">
                <div className="innerhead mb-4">
                  <h5 className="headtitle">
                    {formDetails.length > 0 ? formDetails[0].formName : ""}
                  </h5>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={12} sm={12} className="">
                <div className="formDiv">
                  {!showPreview ? (
                    <>
                      <Form>
                        <Row className="mb-4">

                          <Row>
                            <Col md={6}>
                              <Form.Group className="mb-3 fieldbox h-auto px-2">
                                <Form.Label>Emirates Id Number</Form.Label>
                                <Form.Control

                                  type="text"
                                  placeholder="Enter Emirates Id Number"
                                  className="form-control"
                                  name="emrNumber"
                                  readOnly={formId == propertise.patientAdmit ? false : true}
                                  onChange={handleChangeData}
                                  value={patientDetails?.emrNumber}
                                />
                              </Form.Group>
                            </Col><Col>
                              <Form.Group className="mb-3 fieldbox h-auto px-2">
                                <Form.Label>MRN Number</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter MRN Number"
                                  className="form-control"
                                  onChange={handleChangeMRNNUmber}
                                  name="mrnNumber"
                                  value={patientDetails?.mrnNumber}
                                />
                              </Form.Group>
                            </Col>
                          </Row>

                          {formDetails.length > 0
                            ? formDetails.map((item, index) =>
                              formId == 1 &&
                                item.fieldName == "emrNumber" || item.fieldName == "Mrn Number" || item.fieldName == "Patient Satus" ? null : (
                                <div
                                  className={
                                    item.fieldTypeId == 17
                                      ? "col-md-12 mt-3"
                                      : "col-md-6 mt-3"
                                  }
                                  key={item.id}
                                >
                                  {item.fieldTypeId === 17 && (
                                    <Row>
                                      <Col lg={12} md={12} sm={12}>
                                        <div
                                          style={{
                                            background:
                                              "linear-gradient(268deg, #4B91CE 0%, #5A73B5 100%)",
                                            color: "white",
                                            padding: "10px",
                                            borderRadius: "5px",
                                            textAlign: "center",
                                            fontSize: "16px",
                                            fontWeight: "bold",
                                            textShadow:
                                              "2px 1px 4px rgba(0, 0, 0, 0.25)",
                                          }}
                                        >
                                          <span>{item.fieldName}</span>
                                        </div>
                                      </Col>
                                    </Row>
                                  )}
                                  {item.fieldTypeId != 17 && (
                                    <Form.Group
                                      as={Col}
                                      controlId="formGridEmail"
                                    >
                                      <Form.Label>
                                        {item.fieldtype !== "btn"
                                          ? item.fieldName
                                          : ""}
                                      </Form.Label>{" "}
                                      <br />
                                      {item.fieldtype === "select" ? (
                                        <select
                                          className="form-control"
                                          id={item.fieldName}
                                          name={item.id}
                                          onChange={handleChange}
                                          value={draftData[item.id]}
                                        >
                                          <option value={0}>
                                            Select Department
                                          </option>
                                          {item.dropdownValue != null
                                            ? JSON.parse(
                                              item.dropdownValue
                                            ).map((itemDropdown) => (
                                              <option
                                                key={itemDropdown.fieldValue}
                                                value={
                                                  itemDropdown.fieldValue
                                                }
                                              >
                                                {itemDropdown.fieldValue}
                                              </option>
                                            ))
                                            : ""}
                                        </select>
                                      ) :
                                        item.fieldtype === "selectManual" ? (
                                          <select
                                            className="form-control"
                                            id={item.fieldName}
                                            name={item.id}
                                            onChange={handleChange}
                                            value={draftData[item.id]}
                                          >
                                            <option value={0}>
                                              -Select-
                                            </option>
                                            {item.radioCheckboxValue != null
                                              ? JSON.parse(
                                                item.radioCheckboxValue
                                              ).map((itemDropdown) => (
                                                <option
                                                  key={itemDropdown}
                                                  value={
                                                    itemDropdown
                                                  }
                                                >
                                                  {itemDropdown}
                                                </option>
                                              ))
                                              : ""}
                                          </select>
                                        )
                                          : item.fieldtype === "radio" ||
                                            item.fieldtype === "checkbox" ? (
                                            <>
                                              {item.radioCheckboxValue &&
                                                JSON.parse(
                                                  item.radioCheckboxValue
                                                ).map((radioData) => (
                                                  <React.Fragment key={radioData}>
                                                    <input
                                                      onChange={(e) =>
                                                        handleChange(e, item)
                                                      }
                                                      value={radioData}
                                                      type={item.fieldtype}
                                                      name={item.id}
                                                      id={item.fieldName}
                                                    />{" "}
                                                    {radioData} <br />
                                                  </React.Fragment>
                                                ))}
                                              {item.dropDownFormData ? (
                                                <>
                                                  <div
                                                    style={{
                                                      border: "1px solid grey ",
                                                      padding: "15px",
                                                    }}
                                                  >
                                                    <Row className="mb-4">
                                                      {item.dropDownFormData
                                                        ? item.dropDownFormData.map(
                                                          (item) => (
                                                            <div className="col-md-6 mt-4">
                                                              <Form.Group
                                                                as={Col}
                                                                controlId="formGridEmail"
                                                              >
                                                                <Form.Label>
                                                                  {item.fieldtype !=
                                                                    "btn"
                                                                    ? item.fieldName
                                                                    : ""}
                                                                </Form.Label>{" "}
                                                                <br />
                                                                {item.fieldtype ==
                                                                  "select" ? (
                                                                  <select
                                                                    className="form-control"
                                                                    id={
                                                                      item.fieldName
                                                                    }
                                                                    name={item.id}
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleChangeForSubForm(
                                                                        e,
                                                                        item.formId
                                                                      )
                                                                    }
                                                                  >
                                                                    <option
                                                                      value={0}
                                                                    >
                                                                      Select
                                                                      Department
                                                                    </option>
                                                                    {item.dropdownValue !=
                                                                      null
                                                                      ? JSON.parse(
                                                                        item.dropdownValue
                                                                      ).map(
                                                                        (
                                                                          itemDropdown
                                                                        ) => (
                                                                          <>
                                                                            <option
                                                                              value={
                                                                                itemDropdown.fieldValue
                                                                              }
                                                                            >
                                                                              {
                                                                                itemDropdown.fieldValue
                                                                              }
                                                                            </option>
                                                                          </>
                                                                        )
                                                                      )
                                                                      : ""}
                                                                  </select>
                                                                ) : item.fieldtype ==
                                                                  "radio" ||
                                                                  item.fieldtype ==
                                                                  "checkbox" ? (
                                                                  JSON.parse(
                                                                    item.radioCheckboxValue
                                                                  ).map(
                                                                    (
                                                                      radioData
                                                                    ) => (
                                                                      <>
                                                                        <input
                                                                          onChange={(
                                                                            e
                                                                          ) =>
                                                                            handleChangeForSubForm(
                                                                              e,
                                                                              item.formId
                                                                            )
                                                                          }
                                                                          value={
                                                                            radioData
                                                                          }
                                                                          type={
                                                                            item.fieldtype
                                                                          }
                                                                          name={
                                                                            item.id
                                                                          }
                                                                          id={
                                                                            item.fieldName
                                                                          }
                                                                        />{" "}
                                                                        {
                                                                          radioData
                                                                        }{" "}
                                                                        <br />
                                                                      </>
                                                                    )
                                                                  )
                                                                ) : item.fieldtype ==
                                                                  "btn" ? (
                                                                  ""
                                                                ) : (
                                                                  <Form.Control
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleChangeForSubForm(
                                                                        e,
                                                                        item.formId
                                                                      )
                                                                    }
                                                                    required={
                                                                      item.isMandatory
                                                                        ? true
                                                                        : false
                                                                    }
                                                                    type={
                                                                      item.fieldtype
                                                                    }
                                                                    name={item.id}
                                                                    placeholder={`Enter ${item.fieldName}`}
                                                                    id={
                                                                      item.fieldName
                                                                    }
                                                                    value={
                                                                      formValues[
                                                                      item?.id
                                                                      ]
                                                                    }
                                                                  />
                                                                )}
                                                              </Form.Group>
                                                            </div>
                                                          )
                                                        )
                                                        : ""}
                                                    </Row>
                                                  </div>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          ) : item.fieldtype === "btn" ? (
                                            ""
                                          ) : item.fieldtype === "canvas" ? <>
                                            <MyKonvaComponent name={item.id} id={item.fieldName} imageUrl={item.fieldValue} height={400} width={700} stageRef={stageRefs[index]} index={index} handleSave={handleSave} />

                                          </> : (
                                            <Form.Control
                                              onChange={handleChange}
                                              required={item.isMandatory ? true : false}
                                              type={item.fieldtype}
                                              name={item.id}
                                              value={item.fieldtype !== "file" ? (formId == 1 && item.fieldName == 'MRN Number' ? MRNNumber : draftData[item.id]) : undefined}
                                              placeholder={`Enter ${item.fieldName}`}
                                            />

                                          )}
                                    </Form.Group>
                                  )}
                                </div>
                              )
                            )
                            : ""}

                          &nbsp;
                        </Row>

                        <br />
                        <br />
                        <button
                          className="btn btn-primary me-2"
                          onClick={formPreview}
                          type="submit"
                        >
                          Preview
                        </button>

                        <button className="btn btn-primary me-2" onClick={(e) => handleSubmit(e, 0, 0)}>
                          Save Draft
                        </button>

                        <button className="btn btn-primary" onClick={(e) => resetForm(e)}>
                          Reset
                        </button>
                      </Form>
                    </>
                  ) : (
                    <FormPreview
                      formData={formPreviewValues}
                      onCancel={handleCancel}
                      onSubmit={handleSubmit}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Webform;