import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "../componentCss/webform.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import Dashboardheader from "./dashboardheader";
import AssignedFormSidebar from "./assignedFormSidebar";
import config from '../../config/config';
import "../componentCss/sidebar.css";
import {
  getFormDetailsAction,
  insertFormDataAction,
  getStaffListAction,
  getFormDataListAction,
  getFormDataByMasterAction
} from "../../Action/admin.action";
import toast, { Toaster } from 'react-hot-toast';
import "react-toastify/dist/ReactToastify.css";
import propertise from "../../config/properties";
import { decryptData } from './decrypt';
import Select from "react-select";

const CopyTemplate = () => {
  let { formId, recordId, name } = useParams();
  const navigate = useNavigate();
  const [multipleValues, setMultipleValues] = useState({});
  const [formDetails, setFormDetails] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [subFormValues, setSubFormValues] = useState([]);
  const [subFormDetails, setSubFormDetails] = useState([]);
  const [formPreviewValues, setFormPreviewValues] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const [subFormId, setSubFormId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [staff, setStaff] = useState([]);
  const [formList, setFormData] = useState({});
  const [staffFormEditPermission, setStaffFormEditPermission] = useState('');
  const patientMrnNumber = localStorage.getItem('patientMrnNumber');
  const [masterDataDropDown, setMasterDataDropDown] = useState([]);
  const [selectedOption, setSelectedOption] = useState({
    value: "chocolate",
    label: "Chocolate"
  });
  useEffect(() => {
    getFormDetailsAPI();
    getStaffDetails();
    getFormDataListAPI();
  }, []);

  let getFormDetailsAPI = async () => {
    let res = await getFormDetailsAction({ id: formId });
    if (res.success) {
      setFormDetails(decryptData(res.data));
      setStaffFormEditPermission(decryptData(res.staffFormEditPermission));
      let data = decryptData(res.data);
      data.forEach((item, index) => {
        if (item.fieldTypeId == 20) {
          getFormDataByMasterAPI(item.dropdownFormId, item.dropdownFieldId, index);
        }
      });
    }
  };

  let getFormDataByMasterAPI = async (dropdownFormId, dropdownFieldId, index) => {
    let res = await getFormDataByMasterAction({ dropdownFormId: dropdownFormId, dropdownFieldId: dropdownFieldId });
    if (res.success) {
      setMasterDataDropDown(prevState => {
        const updatedState = [...prevState];
        updatedState[index] = decryptData(res.data);
        return updatedState;
      });
    }
  };

  let getStaffDetails = async () => {
    let res = await getStaffListAction();
    if (res.success) {
      setStaff(decryptData(res.data));
    }
  };

  let getFormDataListAPI = async () => {
    let res = await getFormDataListAction({ id: formId, recordId: recordId, mrnNumber: patientMrnNumber });
    if (res.success) {
      let data = decryptData(res.data?.records)
      const parsedData = parseJSONStringsInObject(data[0]);
      setFormData(parsedData);
    }
  };

  function parseJSONStringsInObject(obj) {
    const result = {};
    for (const key in obj) {
      if (Object.hasOwn(obj, key)) {
        const value = obj[key];
        try {
          const parsedValue = JSON.parse(value);

          if (typeof parsedValue === "object" && parsedValue !== null) {
            result[key] = parsedValue;
          } else {
            result[key] = value;
          }
        } catch (error) {
          result[key] = value;
        }
      }
    }

    return result;
  }

  const handleMultipleValueChange = (e, fieldId, index) => {
    const { value } = e.target;
    setMultipleValues((prevValues) => {
      const updatedValues = { ...prevValues };
      if (!updatedValues[fieldId]) {
        updatedValues[fieldId] = [];
      }
      updatedValues[fieldId][index] = value;
      return updatedValues;
    });
  };

  const removeField = (fieldId, index) => {
    setMultipleValues((prevValues) => {
      const updatedValues = { ...prevValues };
      if (updatedValues[fieldId]) {
        updatedValues[fieldId].splice(index, 1);
      }
      return updatedValues;
    });
  };

  const addMoreFields = (fieldId) => {
    setMultipleValues((prevValues) => {
      const updatedValues = { ...prevValues };
      if (!updatedValues[fieldId]) {
        updatedValues[fieldId] = [];
      }
      updatedValues[fieldId].push('');
      return updatedValues;
    });
  };

  const isJSONStringified = (value) => {
    if (typeof value !== "string") {
      return false;
    }

    try {
      const parsed = JSON.parse(value);
      return typeof parsed === "object" && parsed !== null;
    } catch (e) {
      return false;
    }
  };

  const handleChange = async (e, item) => {
    const { name, value, type, checked, id } = e.target;
    let newValue = "";
    let newValue1 = "";
    if (type === "checkbox") {
      let checkBoxData = formList[name] || [];
      if (isJSONStringified(formList[name])) {
        checkBoxData = JSON.parse(formList[name]);
      }

      if (checked) {
        checkBoxData.push(value);
      } else {
        checkBoxData = checkBoxData.filter((val) => val !== value);
      }
      newValue = checkBoxData;
    } else if (type === "file") {
      newValue = e.target.files[0];
    } else if (name == "assignTo") {
      const [id, ...firstNameParts] = e.target.value.split("_");
      const firstName = firstNameParts.join("_");
      newValue = id;
      newValue1 = firstName;
    } else {
      newValue = value;
    }
    if (type == "radio") {
      if (
        item.dropdownFormId > 0 &&
        (value.trim() == "Yes" ||
          value.trim() == "YES" ||
          value.trim() == "yes")
      ) {
        setSubFormId(item.dropdownFormId);
        let res = await getFormDetailsAction({ id: item.dropdownFormId });
        if (res.success) {
          setSubFormDetails(res.data);
        }
      } else {
        setSubFormDetails([]);
      }
    }
    setFormData({ ...formList, [name]: newValue });
    setFormPreviewValues({
      ...formPreviewValues,
      [id]: newValue1 !== "" ? newValue1 : newValue,
    });
  };

  const convertArrayToJSONString = (data) => {
    const updatedData = {};
    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        updatedData[key] = JSON.stringify(data[key]);
      } else {
        updatedData[key] = data[key];
      }
    });

    return updatedData;
  };

  const handleChangeForSubForm = async (e) => {
    const { name, value, type, checked, id } = e.target;
    let newValue = "";
    if (type === "checkbox") {
      newValue = checked;
    } else if (type === "file") {
      newValue = e.target.files[0];
    } else {
      newValue = value;
    }
    setSubFormValues({ ...subFormValues, [name]: newValue });
    setFormPreviewValues({ ...formPreviewValues, [id]: newValue });
  };

  const handleSubmit = async (e, redirectionType = 0) => {
    e.preventDefault();

    if (staffFormEditPermission.isEdit != 1) {
      toast.error('You do not have edit permission.');
      return;
    }
    setIsLoading(true)
    formList.formId = formId;
    formList.referenceFormId = 0;
    formList.submitType = formList.status
    formList.isEdit = 1;
    subFormValues.formId = subFormId;

    const formFilledData = convertArrayToJSONString(formList);

    let res = await insertFormDataAction({
      formValues: formFilledData,
      subFormValues: subFormValues,
    });
    if (res.success) {
      toast.success(res.msg);
      if (redirectionType == 1) {
        let patientid = res.data[0].id
        if (formId == 3 || formId == 4 || formId == 5) {
          setTimeout(() => {
            window.location.href = `${config.baseUrl}form-submission/${formDetails[0].actionFormId}/0/${patientid}`
          }, 2000);
        } else {
          setTimeout(() => {
            window.location.href = `${config.baseUrl}viewTreatment/${formDetails[0]?.actionFormId}/0/${formDetails[0]?.actionFormName}`
          }, 2000);
        }
      } else {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } else {
      setIsLoading(false)
      toast.error(res.msg);
    }
  };

  const formPreview = (e) => {
    e.preventDefault();
    setShowPreview(true);
  };

  const handleCancel = () => {
    setShowPreview(false);
  };

  function FormPreview({ formData, onCancel, onSubmit }) {
    const [imageUrls, setImageUrls] = useState({});
    useEffect(() => {
      const newImageUrls = {};
      Object.entries(formData).forEach(([key, value]) => {
        if (value instanceof File) {
          newImageUrls[key] = URL.createObjectURL(value);
          return () => URL.revokeObjectURL(newImageUrls[key]);
        }
      });
      setImageUrls(newImageUrls);
    }, [formData]);

    const getDisplayValue = (key, value) => {
      if (value instanceof File) {
        return imageUrls[key] || "";
      }
      return value;
    };

    return (
      <div className="form-preview-container">
        <ul className="list-group">
          {formDetails.map((data) => (
            formData[data.id] &&
            <li key={data.id} className="list-group-item">
              <strong>{data.fieldName}:</strong>{" "}
              {(formData[data.id] instanceof File) ? (
                <div>
                  <img
                    src={getDisplayValue(data.id, formData[data.id])}
                    alt="Preview"
                    style={{ maxWidth: "200px", height: "200px" }}
                  />
                </div>
              ) : (
                getDisplayValue(data.id, formData[data.id])
              )}
            </li>
          ))}
        </ul>
        <div className="button-container">
          <button className="btn btn-secondary mt-3 me-2" onClick={onCancel}>
            Cancel
          </button>
          <button disabled={isLoading == true ? true : false} className="btn btn-primary mt-3" onClick={(e) => handleSubmit(e, 0)}>
            {isLoading == true ? 'Loading...' : 'Submit'}
          </button>

          {formId > 5 &&
            <>
              &nbsp;
              {formDetails[0].isCallToAction === 1 && (
                <button disabled={isLoading == true ? true : false} className="btn btn-primary mt-3" onClick={(e) => handleSubmit(e, 1)}>
                  {isLoading == true ? 'Loading...' : 'Submit & Redirect'}
                </button>
              )}
            </>
          }
        </div>
      </div>
    );
  }

  return (
    <>
      <Dashboardheader />
      <div className="wrapper">
        <div id="content">
          <Container fluid className="p-4">
            <Row>
              <Col lg={3} md={3} sm={3} className=""><AssignedFormSidebar /></Col>
              <Col lg={9} md={9} sm={9} className="">
                <h5 className="headtitle">
                  Edit {formDetails.length > 0 ? formDetails[0].formName : ""}
                </h5>
                <Link to={`${config.baseUrl}viewRecord/${formId}/${recordId}/${name}`}>
                  <span style={{ float: 'right', marginTop: '-15px' }}>Back</span>
                </Link>
                <div className="formDiv">
                  {!showPreview ? (
                    <>
                      <Form>
                        <Row className="mb-4">
                          {formDetails.length > 0
                            ? formDetails.map((item, index) => (
                              <div className="col-md-6 mt-4">
                                <Form.Group
                                  as={Col}
                                  controlId="formGridEmail"
                                >
                                  <Form.Label style={{ textTransform: 'capitalize' }}>
                                    {item.fieldtype != "btn"
                                      ? item.fieldName
                                      : ""}
                                  </Form.Label>{" "}
                                  <br />

                                  {item.fieldtype == "select" ? (
                                    <select
                                      className="form-control"
                                      id={item.fieldName}
                                      name={item.id}
                                      onChange={handleChange}
                                    >
                                      <option value={0}>
                                        Select Department
                                      </option>
                                      {item.dropdownValue != null
                                        ? JSON.parse(item.dropdownValue).map(
                                          (itemDropdown) => (
                                            <>
                                              <option
                                                value={
                                                  itemDropdown.fieldValue
                                                }
                                              >
                                                {itemDropdown.fieldValue}
                                              </option>
                                            </>
                                          )
                                        )
                                        : ""}
                                    </select>
                                  ) : item.fieldtype == "radio" ||
                                    item.fieldtype == "checkbox" ? (
                                    item.radioCheckboxValue &&
                                    JSON.parse(item.radioCheckboxValue).map(
                                      (radioData) => (
                                        <>
                                          <input
                                            onChange={(e) =>
                                              handleChange(e, item)
                                            }
                                            value={radioData}
                                            type={item.fieldtype}
                                            name={item.id}
                                            checked={formList[item.id]?.includes(radioData) || false}
                                            id={item.fieldName}
                                          />{" "}

                                          {radioData} <br />
                                        </>
                                      )
                                    )
                                  ) : item.fieldtype == "btn" ? (
                                    ""
                                  ) : item.fieldtype == "file" ? (
                                    <img src={item.fieldValue} style={{ height: '100px', width: '100px' }} alt="" />
                                  ) :
                                    item.fieldtype == "masterdata" ? (
                                      <Select
                                        id={item.fieldName}
                                        name={item.id}
                                        options={masterDataDropDown[index]?.map((itemDropdown) => ({
                                          value: itemDropdown[item.dropdownFieldId],
                                          label: itemDropdown[item.dropdownFieldId],
                                        }))}
                                        value={
                                          masterDataDropDown[index]?.find(
                                            (option) => option[item.dropdownFieldId] == formList[item.id]
                                          )
                                            ? {
                                              value: formList[item.id],
                                              label: formList[item.id],
                                            }
                                            : null
                                        }
                                        onChange={(selectedOption) =>
                                          handleChange(
                                            { target: { name: item.id, value: selectedOption?.value || "" } },
                                            item
                                          )
                                        }
                                        placeholder="-Select an Option-"
                                        isClearable
                                      />

                                    ) :
                                      item.fieldtype == 'multipleValue' ?
                                        <>
                                          <span style={{ display: "none" }}>
                                            {multipleValues[item.id] = formList[item.id] || ['']}
                                          </span>
                                          {multipleValues[item.id].map((value, index) => (
                                            <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                              <Form.Control
                                                onChange={(e) => handleMultipleValueChange(e, item.id, index)}
                                                type="text"
                                                name={`${item.id}_${index}`}
                                                value={value}
                                                placeholder={`Enter ${item.fieldName}`}
                                              />
                                              {multipleValues[item.id].length > 1 && (
                                                <button type="button" className="btn btn-sm btn-primary" onClick={() => removeField(item.id, index)}>
                                                  Remove
                                                </button>
                                              )}
                                            </div>
                                          ))}
                                          <button type="button" className="btn btn-sm btn-primary mt-1" onClick={() => addMoreFields(item.id)}>
                                            Add More
                                          </button>
                                        </>
                                        :
                                        item.id == propertise.PatientUniqueId || item.id == propertise.PatientMrnNumber
                                          ?
                                          <Form.Control
                                            onChange={handleChange}
                                            required={
                                              item.isMandatory ? true : false
                                            }
                                            type={item.fieldtype}
                                            readOnly
                                            name={item.id}
                                            placeholder={`Enter ${item.fieldName}`}
                                            id={item.fieldName}
                                            value={formList[item.id]}
                                          />
                                          :
                                          <Form.Control
                                            onChange={handleChange}
                                            required={
                                              item.isMandatory ? true : false
                                            }
                                            type={item.fieldtype}
                                            name={item.id}
                                            placeholder={`Enter ${item.fieldName}`}
                                            id={item.fieldName}
                                            value={formList[item.id]}
                                          />
                                  }
                                </Form.Group>
                              </div>
                            ))
                            : ""}
                        </Row>
                        {subFormDetails.length > 0 ? (
                          <div
                            style={{
                              border: "1px solid grey ",
                              padding: "15px",
                            }}
                          >
                            <Row className="mb-4">
                              {subFormDetails.length > 0
                                ? subFormDetails.map((item) => (
                                  <div className="col-md-6 mt-4">
                                    <Form.Group
                                      as={Col}
                                      controlId="formGridEmail"
                                    >
                                      <Form.Label>
                                        {item.fieldtype != "btn"
                                          ? item.fieldName
                                          : ""}
                                      </Form.Label>{" "}
                                      <br />
                                      {item.fieldtype == "select" ? (
                                        <select
                                          className="form-control"
                                          id={item.fieldName}
                                          name={item.id}
                                          onChange={handleChangeForSubForm}
                                        >
                                          <option value={0}>
                                            Select Department
                                          </option>
                                          {item.dropdownValue != null
                                            ? JSON.parse(
                                              item.dropdownValue
                                            ).map((itemDropdown) => (
                                              <>
                                                <option
                                                  value={
                                                    itemDropdown.fieldValue
                                                  }
                                                >
                                                  {itemDropdown.fieldValue}
                                                </option>
                                              </>
                                            ))
                                            : ""}
                                        </select>
                                      ) : item.fieldtype == "radio" ||
                                        item.fieldtype == "checkbox" ? (
                                        JSON.parse(
                                          item.radioCheckboxValue
                                        ).map((radioData) => (
                                          <>
                                            <input
                                              onChange={(e) =>
                                                handleChangeForSubForm(
                                                  e,
                                                  item
                                                )
                                              }
                                              value={radioData}
                                              type={item.fieldtype}
                                              name={item.id}
                                              id={item.fieldName}
                                            />{" "}
                                            {radioData} <br />
                                          </>
                                        ))
                                      ) : item.fieldtype == "btn" ? (
                                        ""
                                      ) :
                                        (
                                          <Form.Control
                                            onChange={handleChangeForSubForm}
                                            required={
                                              item.isMandatory ? true : false
                                            }
                                            type={item.fieldtype}
                                            name={item.id}
                                            placeholder={`Enter ${item.fieldName}`}
                                            id={item.fieldName}
                                            value={formList[item.id]}
                                          />
                                        )}
                                    </Form.Group>
                                  </div>
                                ))
                                : ""}
                            </Row>
                          </div>
                        ) : (
                          ""
                        )}
                        <br />
                        <br />
                        <button
                          className="btn btn-primary"
                          onClick={formPreview}
                          type="submit"
                        >
                          Preview
                        </button>
                      </Form>
                    </>
                  ) : (
                    <FormPreview
                      formData={formList}
                      onCancel={handleCancel}
                    // onSubmit={handleSubmit}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};
export default CopyTemplate;
