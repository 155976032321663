import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { commonService } from "../../services/common.services";
import { apiConstants } from "../../constants/api.constant";
// import { toast } from 'react-toastify';
import toast from 'react-hot-toast';
import Cookies from "js-cookie";

// Add Report Details 

export const addReports = createAsyncThunk('report/addReports', async (data) => {
 
  const response = await commonService.withToken(apiConstants.ADD_REPORTS ,data); 
  if(response && response.data && response.data.success){
    
  }else{
    toast.error(
      response.data.msg, {
        position: "top-right",
      }
    )
  }
 
  return response.data; 
});

// Add Report Details 

export const addReportsReason = createAsyncThunk('report/addReportsReason', async (data) => {
 
  const response = await commonService.withToken(apiConstants.ADD_REPORT_REASON ,data); 
  if(response && response.data && response.data.success){
    
  }else{
    toast.error(
      response.data.msg, {
        position: "top-right",
      }
    )
  }
 
  return response.data; 
});


// Get Report Details 

export const getReports = createAsyncThunk('report/getReports', async (data) => { 
  const response = await commonService.getWithToken(apiConstants.GET_REPORTS ,data); 
  if(response && response.data && response.data.success){
    
  }else{
    toast.error(
      response.data.msg, {
        position: "top-right",
      }
    )
  }
  return response.data; 
});


const reportsSlice = createSlice({
  name: 'report',
  initialState: {
    loading: false,
    error: null,
    addReportsData: null,
    getReportsData: null,
    addReportReasonData: null
  },
  reducers: {
    changeValue: (state, action) => {
      Object.assign(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addReports.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addReports.fulfilled, (state, action) => {
        state.loading = false;
        state.addReportsData = action.payload;
      })
      .addCase(addReports.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getReports.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getReports.fulfilled, (state, action) => {
        state.loading = false;
        state.getReportsData = action.payload;
      })
      .addCase(getReports.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addReportsReason.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addReportsReason.fulfilled, (state, action) => {
        state.loading = false;
        state.addReportReasonData = action.payload;
      })
      .addCase(addReportsReason.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  },
});


export const { changeValue } = reportsSlice.actions;

export default reportsSlice.reducer 