import * as Yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]?$/;
const nameRegExp = /^[A-Za-z]+$/;

export const addStaffSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short Name!")
    .max(50, "Too Long Name!")
    .required("First name is required."),
  lastName: Yup.string()
    .min(2, "Too Short Name!")
    .max(50, "Too Long Name!")
    .required("Last name is required."),
  email: Yup.string().email("Invalid email").required("Email is required."),
  mobileNumber: Yup.string()
    .matches(phoneRegExp, " only Numeric values allowed")
    .required("Phone number is required."),
  password: Yup.string()
    .required("Password is required."),
    // .max(18, "Password is too long - should be maximum 20 chars.")
    // .min(7, "Password is too short - should be 8 chars minimum.")
    // .matches(/[0-9]/, "Password requires a number")
    // .matches(/[a-z]/, "Password requires a lowercase letter")
    // .matches(/[A-Z]/, "Password requires an uppercase letter")
    // .matches(/[^\w]/, "Password requires a special chars"),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref("password"), null],
      "Password and confirm password does not match"
    )
    .required("Confirm password is required."),
  address: Yup.string().required("Address is required."),
  city: Yup.string().required("City is required."),
  state: Yup.string().required("State is required."),
  employeeId: Yup.string().required("Employee-Id is required."),

  licenceNumber: Yup.string().required("Licence Number  is required."),
  emiratesIdNumber: Yup.string().required("Emirates Id is required."),
  designation: Yup.string().required("Designation is required."),
  DateOfJoining: Yup.string().required("Date Of Joining is required."),
  gender: Yup.string().required("Gender is required."),
  userRoleId : Yup.array()
    .min(1, "At least one role must be selected")
    .required("This field is required"),
  stampImage: Yup.mixed()
    .required("Stamp image is required.")
    .test("fileType", "Unsupported file format", (value) => {
      return (
        value &&
        [
          "image/gif",
          "image/png",
          "image/jpeg",
          "image/jpg",
          "image/svg",
          "image/webp",
        ].includes(value.type)
      );
    }),
  photo: Yup.mixed()
    .required("Photo is required.")
    .test("fileType", "Unsupported file format", (value) => {
      return (
        value &&
        [
          "image/gif",
          "image/png",
          "image/jpeg",
          "image/jpg",
          "image/svg",
          "image/webp",
        ].includes(value.type)
      );
    }),
  signature: Yup.mixed()
    .required("Signature image is required.")
    .test("fileType", "Unsupported file format", (value) => {
      return (
        value &&
        [
          "image/gif",
          "image/png",
          "image/jpeg",
          "image/jpg",
          "image/svg",
          "image/webp",
        ].includes(value.type)
      );
    }),
});

export const editStaffSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short Name!")
    .max(50, "Too Long Name!")
    .required("First name is required."),
  lastName: Yup.string()
    .min(2, "Too Short Name!")
    .max(50, "Too Long Name!")
    .required("Last name is required."),
  email: Yup.string().email("Invalid email").required("Email is required."),
  mobileNumber: Yup.string()
    .matches(phoneRegExp, " only Numeric values allowed")
    .required("Phone number is required."),
  //   password: Yup.string()
  //   .max(18, "Password is too long - should be maximum 20 chars.")
  //   .min(7, "Password is too short - should be 8 chars minimum.")
  //   .matches(/[0-9]/, "Password requires a number")
  //   .matches(/[a-z]/, "Password requires a lowercase letter")
  //   .matches(/[A-Z]/, "Password requires an uppercase letter")
  //   .matches(/[^\w]/, "Password requires a special chars"),
  // confirmPassword: Yup.string()
  //   .oneOf(
  //     [Yup.ref("password"), null],
  //     "Password and confirm password does not match"
  //   ),
  address: Yup.string().required("Address is required."),
  city: Yup.string().required("City is required."),
  state: Yup.string().required("State is required."),
  employeeId: Yup.string().required("Employee-Id is required."),
  emiratesIdNumber: Yup.string().required("Emirates Id is required."),
  licenceNumber: Yup.string().required("Licence Number  is required."),

  designation: Yup.string().required("Designation is required."),
  DateOfJoining: Yup.string().required("Date Of Joining is required."),
  gender: Yup.string().required("Gender is required."),
  stampImage: Yup.mixed().required("Stamp image is required."),
  // .test('fileType', 'Unsupported file format', (value) => {
  //     return value && ['image/gif','image/png','image/jpeg','image/jpg','image/svg','image/webp',].includes(value.type);
  // }),
  photo: Yup.mixed().required("Photo is required."),
  // .test('fileType', 'Unsupported file format', (value) => {
  //     return value && ['image/gif','image/png','image/jpeg','image/jpg','image/svg','image/webp'].includes(value.type);
  // }),
  signature: Yup.mixed().required("Signature image is required."),
  // .test('fileType', 'Unsupported file format', (value) => {
  //     return value && ['image/gif','image/png','image/jpeg','image/jpg','image/svg','image/webp' ].includes(value.type);
  // })
});

export const profileSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short Name!")
    .max(50, "Too Long Name!")
    .required("First name is required."),
  lastName: Yup.string()
    .min(2, "Too Short Name!")
    .max(50, "Too Long Name!")
    .required("Last name is required."),
  email: Yup.string().email("Invalid email").required("Email is required."),
  mobileNumber: Yup.string()
    .matches(phoneRegExp, " only Numeric values allowed")
    .required("Phone number is required."),
  address: Yup.string().required("Address is required."),
  gender: Yup.string().required("Gender is required."),
});

export const addDepartmentSchema = Yup.object().shape({
  name: Yup.string().required("Name is required."),
});

export const addDepartmentFormsSchema = Yup.object().shape({
  name: Yup.string().required("Name is required."),
  departmentId: Yup.string().required("Select department."),
});
export const editPasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Old Password is required."),
  newPassword: Yup.string()
    .required("Password is required.")
    .max(18, "Password is too long - should be maximum 20 chars.")
    .min(7, "Password is too short - should be 8 chars minimum.")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a special chars"),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref("newPassword"), null],
      "Password and confirm password does not match"
    )
    .required("Confirm password is required."),
});




