import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import '../componentCss/webform.css';
import { useNavigate } from "react-router-dom";
import Dashboardheader from './dashboardheader';
import Sidebar from './sidebar';
import '../componentCss/sidebar.css';
import { BsFillTrash3Fill, BsFilePlusFill } from "react-icons/bs";
// import { ToastContainer, toast } from 'react-toastify';
import toast from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';
import { BsArrowLeftCircleFill } from "react-icons/bs";
import config from '../../config/config';
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

import {
    addWebFormFieldsAction,
    getFormListAction,
    getFieldTypeAction,
    getDepartmentAction,
    getFormTypeListAction,
    getFormDetailsAction,
    getFormFieldsDataAction,
    updateWebFormAction,
    deleteWebFormFieldAction,
    getCategoryListForWebFormAction,
    getMasterListAction,
    getMasterTableColumnAction
} from '../../Action/admin.action';
import { decryptData } from './decrypt';

let fieldArr = [];
let radioFieldValueArr = [];
let radioAddFieldArr = [
    [{ radioCheckboxValue: "" }]
]

const EditWebForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [formData, setForm] = useState({ formName: '', formCategory: '', formNumber: '', departmentId: 0, formTypeId: 0, isSideBarAccess: 0, isLateEntry: 0, lateEntryTime: 0, isCallToAction: 0, callToActionLink: 0 })
    const [formNumber, setformNumber] = useState('')
    const [formValues, setFormValues] = useState([{ fieldtypeId: "", fieldtype: "", fieldName: "", action: '', isMandatory: 0, isAddMore: 0, description: '', fieldValue: '', formType: '', fieldtype: '', radioCheckbox: [], isFieldSorting: '' }])
    const [formRadioCheckbox, setFormRadioCheckbox] = useState([{ radioCheckboxValue: "" }])
    const [formfieldsList, setFormfieldsList] = useState([])
    const [formFieldTypeList, setFormFieldTypeList] = useState([])
    const [departmentList, setDepartmentList] = useState([])
    const [formTypeList, setFormTypeList] = useState([])
    const [formList, setFormList] = useState([])
    const [rowIndex, setRowIndex] = useState(0)
    const [existingFormFields, setExistingFormFields] = useState([])
    const [formCategoryList, setFormCategoryList] = useState([]);
    const [masterList, setMasterData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [columDataList, setColumDataList] = useState([])

    useEffect(() => {
        getFormFieldsData()
        getFieldTypeAPI()
        getDepartmentAPI()
        getFormListAPI()
        getFormCategoryAPI()
        getMasterList()
        getFormDataListAPI()
    }, []);


    let getMasterList = async () => {
        let res = await getMasterListAction();
        setIsLoading(false)
        if (res.success) {
            setMasterData(decryptData(res.data));
        }
    }

    let getFormDataListAPI = async (value, index) => {
        setIsLoading(true);
        try {
            let res = await getMasterTableColumnAction({ name: value });
            setIsLoading(false);

            if (res?.success) {
                let columnData = decryptData(res.data);
                setFormValues((prev) =>
                    prev.map((item, idx) =>
                        idx === index ? { ...item, columDataList: columnData } : item
                    )
                );
            } else {
                setColumDataList([]);
            }
        } catch (error) {
            setIsLoading(false);
        }
    };


    let handleChangeForm = (e) => {
        let { name, value } = e.target
        if (name == 'departmentId') {
            getFormTypeListAPI(value);
        }

        setForm((old) => {
            return { ...old, [name]: value }
        })
    }

    let getFormCategoryAPI = async () => {
        let res = await getCategoryListForWebFormAction();
        if (res.success) {
            setFormCategoryList(decryptData(res.data));
        }
    };

    let getFormFieldsData = async () => {
        let res = await getFormFieldsDataAction({ formId: id });
        if (res.success) {
            let data = decryptData(res.data);
            setExistingFormFields(data?.formFieldsResult);
            setForm(data?.formResult[0])
            setformNumber(data?.formResult[0]?.formNumber)
            getFormTypeListAPI(data?.formResult[0]?.departmentId)
        }
    }

    const handleChangeData = (index, e) => {
        const { name, value } = e.target;

        // Update the form values based on index
        setFormValues((prev) =>
            prev.map((item, idx) =>
                idx === index ? { ...item, [name]: value } : item
            )
        );

        // Additional logic can be added here if needed for further indexing
    };

    let getFormFieldsListAPI = async (index, formId) => {
        let res = await getFormDetailsAction({ id: formId });
        if (res.success) {
            fieldArr[index] = decryptData(res.data);
            setFormfieldsList(decryptData(res.data));
        }
    }

    let getFormListAPI = async () => {
        let res = await getFormListAction();
        if (res.success) {
            setFormList(decryptData(res.data));
        }
    }

    let getDepartmentAPI = async () => {
        let res = await getDepartmentAction();
        if (res.success) {
            setDepartmentList(decryptData(res.data));
        }
    }

    let handleChangeTable = (index, e) => {
        const { name, value } = e.target;

        // Update the form values based on index
        setFormValues((prev) =>
            prev.map((item, idx) =>
                idx === index ? { ...item, [name]: value } : item
            )
        );

        // Call the master list retrieval function with the selected value
        getMasterListForName(index, value);
    };

    let getMasterListForName = async (index, id) => {
        let res = await getMasterListAction();
        setIsLoading(false);

        if (res.success) {
            let data = decryptData(res.data);

            // Filter the data based on the provided ID
            let filterData = data.filter((item) => item.id == id);

            // Fetch related form data for the corresponding dbTable
            if (filterData.length > 0) {
                getFormDataListAPI(filterData[0].dbTable, index);
            }
        }
    };


    let getFormTypeListAPI = async (id) => {
        let res = await getFormTypeListAction({ 'departmentId': id });
        if (res.success) {
            setFormTypeList(decryptData(res.data));
        } else {
            setFormTypeList([]);
        }
    }

    let getFieldTypeAPI = async () => {
        let res = await getFieldTypeAction();
        if (res.success) {
            setFormFieldTypeList(decryptData(res.data));
        }
    }

    let handleChange = (i, e) => {
        let newFormValues = [...existingFormFields];
        newFormValues[i][e.target.name] = e.target.name == 'isMandatory' ? e.target.checked : e.target.value;
        newFormValues[i][e.target.name] = e.target.name == 'isAddMore' ? e.target.checked : e.target.value;
        setExistingFormFields(newFormValues);
    }

    let handleChangeForNewField = (i, e) => {
        let newFormValues = [...formValues];

        if (e.target.name == 'formId') {
            getFormFieldsListAPI(i, e.target.value);
        }

        if (e.target.name == 'fieldtype') {
            let fieldTypeValue = formFieldTypeList.find((element) => {
                return element.id == e.target.value;
            })
            newFormValues[i]['fieldtype'] = fieldTypeValue.fieldType;
            newFormValues[i]['fieldtypeId'] = e.target.value;
        } else {
            newFormValues[i][e.target.name] = e.target.value;
        }
        setFormValues(newFormValues);
    }

    const handleAddFile = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i]['filedValue'] = e.target.files[0];
        setFormValues(newFormValues);

    }

    let handleChangeRadio = (i, e) => {
        let newFormValues = [...formValues];
        radioFieldValueArr[i] = e.target.value
        newFormValues[rowIndex]['radioCheckbox'] = radioFieldValueArr
        setFormValues(newFormValues);
    }

    let handleChangeRadioForEdit = (indexOfRow, i, e, data) => {
        let radioFieldValueArr = JSON.parse(data);
        let newFormValues = [...existingFormFields];
        radioFieldValueArr[i] = e.target.value
        newFormValues[indexOfRow]['radioCheckbox'] = JSON.stringify(radioFieldValueArr)
        newFormValues[indexOfRow]['radioCheckboxValue'] = JSON.stringify(radioFieldValueArr)
        setExistingFormFields(newFormValues);
    }

    let addFormFields = () => {
        fieldArr.push([])
        radioFieldValueArr = []
        radioAddFieldArr[rowIndex + 1] = [{ radioCheckboxValue: "" }]
        setFormValues([...formValues, { fieldtypeId: "", fieldtype: "", fieldName: "", action: '', isMandatory: 0, isAddMore: 0, description: '', filedValue: '', formType: '', fieldtype: '', radioCheckbox: [] }])
        setRowIndex(rowIndex + 1);
    }

    let removeFormFields = (i) => {
        setRowIndex(rowIndex - 1);
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    }

    let addRadioCheckboxFormFields = (index) => {
        radioAddFieldArr[index].push({ radioCheckboxValue: "" })
        setFormRadioCheckbox([...formRadioCheckbox, { radioCheckboxValue: "" }])
    }

    let addRadioCheckboxFormFieldsForEdit = (i, data) => {
        let radioFieldValueArr = JSON.parse(data);
        radioFieldValueArr.push("");
        let newFormValues = [...existingFormFields];
        newFormValues[i]['radioCheckbox'] = JSON.stringify(radioFieldValueArr)
        newFormValues[i]['radioCheckboxValue'] = JSON.stringify(radioFieldValueArr)
        setExistingFormFields(newFormValues);
    }

    let removeFormFieldsForEdit = (indexOfField, i, data) => {
        let radioFieldValueArr = JSON.parse(data);
        radioFieldValueArr.splice(indexOfField, 1);
        let newFormValues = [...existingFormFields];
        newFormValues[i]['radioCheckbox'] = JSON.stringify(radioFieldValueArr)
        newFormValues[i]['radioCheckboxValue'] = JSON.stringify(radioFieldValueArr)
        setExistingFormFields(newFormValues);
    }
    let handleSubmit = async (event) => {
        event.preventDefault();

        let isAllFieldsFill = 0;
        for (let i = 0; i < formValues.length; i++) {
            if (!formValues[i].fieldName) {
                isAllFieldsFill = 1;
                break;
            }
        }

        if (isAllFieldsFill) {
            toast.error('All fields are required.');
            return;
        }
        let formData = new FormData();
        formData.append('formId', id);

        // Loop through each object in formData
        for (let i = 0; i < formValues.length; i++) {
            let field = formValues[i];

            // Append each key-value pair to the FormData
            for (let key in field) {
                if (field.hasOwnProperty(key)) {
                    // Check if the field value is an image file
                    if (key === 'fieldValue' && field.fieldtype === 'image' && field[key] instanceof File) {
                        formData.append(key, field[key]); // Append the image file
                    } else {
                        formData.append(`fieldsData[${i}][${key}]`, field[key]);
                    }
                }
            }
        }
        let res = await addWebFormFieldsAction(formData);
        if (res.success) {
            toast.success(res.msg);
            // setTimeout(() => {
            //     window.location.reload();
            // }, 2000);
        } else {
            toast.error(res.msg);
        }
    }


    let updateFormFileds = async (event) => {
        event.preventDefault();

        let res = await updateWebFormAction({ 'existingFormFields': existingFormFields, 'formData': formData });
        if (res.success) {
            toast.success(res.msg);
        } else {
            toast.error(res.msg);
        }
    }

    const deleteFields = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete this field.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes delete it",
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await deleteWebFormFieldAction({ 'id': id });
                if (res.success) {
                    getFormFieldsData();
                    toast.success(res.msg);
                } else {
                    toast.error(res.msg);
                }
            }
        });
    }

    return (
        <>
            <Dashboardheader />
            {/* <ToastContainer /> */}
            <div className="wrapper">
                <Sidebar />
                <div id="content">
                    <Container fluid className="p-4">
                        <Row>
                            <Col lg={12} md={12} sm={12} className="">
                                <div className="innerhead mb-4">
                                    <h5 className='headtitle'>Edit Form Fields</h5>
                                </div>
                                <Link to={`${config.baseUrl}webforms`} >
                                    <span style={{ float: 'right' }}>Back <BsArrowLeftCircleFill /></span>
                                </Link>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12} md={12} sm={12} className="">
                                <div className='formDiv'>
                                    <Form>
                                        <Row className="mb-4">
                                            <Form.Group as={Col} controlId="formGridEmail">
                                                <Form.Label>Form name</Form.Label>
                                                <input className='form-control' type="text" onChange={handleChangeForm} name='formName' value={formData?.formName} placeholder="Enter form name" />
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="formGridPassword">
                                                <Form.Label>Form Number</Form.Label>
                                                <input className='form-control' type="text" readOnly disabled value={formNumber} />
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="formGridEmail">
                                                <Form.Label>Department</Form.Label>
                                                <select className='form-control' name='departmentId' onChange={handleChangeForm} value={formData?.departmentId} >
                                                    <option value={0}>Select Department</option>
                                                    {departmentList.map(data => (
                                                        <option value={data.id}>{data.name.toUpperCase()}</option>
                                                    ))}
                                                </select>
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="formGridPassword">
                                                <Form.Label>Folder Type</Form.Label>
                                                <select className='form-control' name='formTypeId' onChange={handleChangeForm} value={formData?.formTypeId} >
                                                    <option value={0}>Select Folder Type</option>
                                                    {formTypeList.map(data => (
                                                        <option value={data.id}>{data.name}</option>
                                                    ))}
                                                </select>
                                            </Form.Group>
                                        </Row>

                                        <Row className="mb-4">
                                            <Form.Group as={Col} controlId="formGridPassword">
                                                <Form.Label>Form Category</Form.Label>
                                                <select
                                                    className="form-control"
                                                    name="formCategory"
                                                    onChange={handleChangeForm}
                                                    value={formData?.formCategory}
                                                >
                                                    <option value="0">-Select-</option>
                                                    {formCategoryList.map(data => (
                                                        <option value={data.id}>{data.name}</option>
                                                    ))}
                                                </select>
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="formGridPassword">
                                                <Form.Label>Late Entry</Form.Label>
                                                <select
                                                    className="form-control"
                                                    name="isLateEntry"
                                                    onChange={handleChangeForm}
                                                    value={formData?.isLateEntry}
                                                >
                                                    <option value="0">No</option>
                                                    <option value="1">Yes</option>
                                                </select>
                                            </Form.Group>

                                            {formData?.isLateEntry == 1 &&
                                                <Form.Group as={Col} controlId="formGridPassword">
                                                    <Form.Label>Late entry time(In Hour)</Form.Label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="lateEntryTime"
                                                        value={formData?.lateEntryTime}
                                                        onChange={handleChangeForm}
                                                        onKeyPress={(event) => {
                                                            if (!/^[0-9]*$/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </Form.Group>
                                            }

                                            <Form.Group as={Col} controlId="formGridPassword">
                                                <Form.Label>Call To Action Button</Form.Label>
                                                <select
                                                    className="form-control"
                                                    name="isCallToAction"
                                                    value={formData?.isCallToAction}
                                                    onChange={handleChangeForm}
                                                >
                                                    <option value="0">No</option>
                                                    <option value="1">Yes</option>
                                                </select>
                                            </Form.Group>

                                            {formData?.isCallToAction == 1 &&
                                                <Form.Group as={Col} controlId="formGridPassword">
                                                    <Form.Label>Action(Form Number)</Form.Label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="callToActionLink"
                                                        value={formData.callToActionLink}
                                                        placeholder="Ex. 698815462"
                                                        onChange={handleChangeForm}
                                                    />
                                                </Form.Group>
                                            }
                                        </Row>
                                    </Form>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12} md={12} sm={12} className="mt-0">
                                <div className='maintable table-responsive'>
                                    <h5 className=''>Existing Form Fields</h5>
                                    <form onSubmit={updateFormFileds} className='mt-4'>
                                        {existingFormFields.map((element, index) => (
                                            <div className="form-inline mt-2" key={index}>
                                                <div className='row'>

                                                    <div className='col-md-1'>
                                                        {index == 0 ?
                                                            <> <span>No.</span> <br /></>
                                                            : ""}
                                                        <label>{index + 1}</label>
                                                    </div>

                                                    <div className='col-md-2'>
                                                        {index == 0 ?
                                                            <> <span>Field Type </span> <br /></>
                                                            : ""}
                                                        <select name='fieldtype' className='form-control' disabled value={element?.fieldtypeId} >
                                                            <option value="0">Select Type</option>
                                                            {formFieldTypeList.map(item => (
                                                                <option value={item.id}>{item.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    {element.fieldtypeId == 20 ?
                                                        <>
                                                            <div className="col-md-2">
                                                                {index === 0 ? (
                                                                    <>
                                                                        <span>Table Type</span> <br />
                                                                    </>
                                                                ) : null}
                                                                <select
                                                                    name="dropdownFormId"
                                                                    className="form-control"
                                                                    disabled
                                                                    //   onChange={(e) => handleChangeTable(index, e)}
                                                                    value={element?.dropdownFormId}
                                                                >
                                                                    <option value="0">Select Table</option>
                                                                    {masterList.map((item) => (
                                                                        <option key={item.dbTable} value={item.id}>
                                                                            {item.dbTable}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>

                                                            <div className="col-md-2">
                                                                {index === 0 ? (
                                                                    <>
                                                                        <span>Column Type</span> <br />
                                                                    </>
                                                                ) : null}
                                                                <input type='text' className='form-control' value={element?.dropdownFieldId} disabled />

                                                            </div>
                                                        </>
                                                        : ""
                                                    }




                                                    <div className='col-md-2'>
                                                        {index == 0 ?
                                                            <> <span>Field Name</span> <br /></>
                                                            : ""}
                                                        <input type="text" className='form-control' name="fieldName" value={element.fieldName || ""} onChange={e => handleChange(index, e)} />
                                                    </div>

                                                    {element.fieldtypeId == 14 || element.fieldtypeId == 11 || element.fieldtypeId == 10 ?
                                                        <div className='col-md-3'>
                                                            {element.radioCheckboxValue && JSON.parse(element.radioCheckboxValue).map((dropdownValue, indexOfField) => (
                                                                <div className="form-inline mt-1" key={indexOfField}>
                                                                    <input type="text" placeholder='Enter value' className='form-control' name="radioCheckboxValue" onChange={e => handleChangeRadioForEdit(index, indexOfField, e, element.radioCheckboxValue)} value={dropdownValue} />
                                                                    <BsFillTrash3Fill style={{ float: 'right', marginTop: '-30px', cursor: 'pointer' }} onClick={() => removeFormFieldsForEdit(indexOfField, index, element.radioCheckboxValue)} />

                                                                </div>
                                                            ))}
                                                            <div className='col-md-1'>
                                                                <div className="button-section">
                                                                    <BsFilePlusFill onClick={() => addRadioCheckboxFormFieldsForEdit(index, element.radioCheckboxValue)} />
                                                                </div>
                                                            </div>

                                                        </div>
                                                        :
                                                        <div className='col-md-3'></div>
                                                    }

                                                    <div className='col-md-1'>
                                                        {index == 0 ?
                                                            <> <span>Mandatory</span> <br /></>
                                                            : ""}
                                                        <input type="checkbox" name="isMandatory" value={1} checked={element.isMandatory ? true : false} onChange={e => handleChange(index, e)} />
                                                    </div>
                                                    {element.fieldtypeId == 1 ?

                                                        <div className='col-md-1'>
                                                            {index == 0 ?
                                                                <> <span>Add More</span> <br /></>
                                                                : ""}
                                                            <input type="checkbox" name="isAddMore" value={1} checked={element.isAddMore ? true : false} onChange={e => handleChange(index, e)} />
                                                        </div> : ""
                                                    }

                                                    <div className='col-md-1'>
                                                        {index == 0 ?
                                                            <> <span>Field Sorting</span> <br /></>
                                                            : ""}
                                                        <input type="text" className='form-control' name="isFieldSorting" value={element.isFieldSorting || ""} onChange={e => handleChange(index, e)} />
                                                    </div>

                                                    <div className='col-md-1'>
                                                        {index == 0 ?
                                                            <> <span>Action</span> <br /></>
                                                            : ""}
                                                        {
                                                            <BsFillTrash3Fill style={{ cursor: 'pointer' }} onClick={() => deleteFields(element.id)} />
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                        ))}
                                        <br />
                                        <div className="button-section">
                                            <Button variant="secondary-btn3" type='submit' className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6'>Update</Button>
                                        </div>
                                    </form>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12} md={12} sm={12} className="mt-0">
                                <div className='maintable table-responsive'>
                                    <h5 className=''>Add New Fields</h5>
                                    <form onSubmit={handleSubmit} className='mt-4'>
                                        {formValues.map((element, index) => (
                                            <div className="form-inline mt-2" key={index}>
                                                <div className='row'>

                                                    <div className='col-md-1'>
                                                        {index == 0 ?
                                                            <> <span>No.</span> <br /></>
                                                            : ""}
                                                        <label>{index + 1}</label>
                                                    </div>

                                                    <div className='col-md-2'>
                                                        {index == 0 ?
                                                            <> <span>Field Type</span> <br /></>
                                                            : ""}
                                                        <select name='fieldtype' className='form-control' onChange={e => handleChangeForNewField(index, e)}>
                                                            <option value="0">Select Type</option>
                                                            {formFieldTypeList.map(item => (
                                                                <option value={item.id}>{item.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>

                                                    {formValues[index].fieldtype === "masterdata" ? (
                                                        <>
                                                            <div className="col-md-2">
                                                                {index === 0 ? (
                                                                    <>
                                                                        <span>Table Type</span> <br />
                                                                    </>
                                                                ) : null}
                                                                <select
                                                                    name="dropdownFormId"
                                                                    className="form-control"
                                                                    onChange={(e) => handleChangeTable(index, e)}
                                                                    value={formValues[index]?.dropdownFormId || "0"}
                                                                >
                                                                    <option value="0">Select Table</option>
                                                                    {masterList.map((item) => (
                                                                        <option key={item.dbTable} value={item.id}>
                                                                            {item.dbTable}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>

                                                            <div className="col-md-2">
                                                                {index === 0 ? (
                                                                    <>
                                                                        <span>Column Type</span> <br />
                                                                    </>
                                                                ) : null}
                                                                <select
                                                                    name="dropdownFieldId"
                                                                    className="form-control"
                                                                    value={formValues[index]?.dropdownFieldId || "0"}
                                                                    onChange={(e) => handleChangeData(index, e)}
                                                                >
                                                                    <option value="0">Select Column</option>
                                                                    {formValues[index]?.columDataList?.column?.map((item) => (
                                                                        <option key={item.column_name} value={item.column_name}>
                                                                            {item.column_name}
                                                                        </option>
                                                                    ))}
                                                                </select>

                                                            </div>

                                                        </>
                                                    ) : null}

                                                    <div className='col-md-2'>
                                                        {index == 0 ?
                                                            <> <span>Field Name</span> <br /></>
                                                            : ""}
                                                        <input type="text" className='form-control' name="fieldName" value={element.fieldName || ""} onChange={e => handleChangeForNewField(index, e)} />
                                                    </div>

                                                    {formValues[index].fieldtype == 'btn' ?
                                                        <div className='col-md-2'>
                                                            {index == 0 ?
                                                                <> <span>Action</span> <br /></>
                                                                : ""}
                                                            <input type="text" className='form-control' name="action" value={element.action || ""} onChange={e => handleChangeForNewField(index, e)} />
                                                        </div>
                                                        :
                                                        ""}

                                                    {formValues[index].fieldtype == 'radio' || formValues[index].fieldtype == 'checkbox' || formValues[index].fieldtype == 'selectManual' ?
                                                        <div className='col-md-3'>
                                                            {index == 0 ?
                                                                <> <span>{formValues[index].fieldtype == 'radio' ? "Radio value" : formValues[index].fieldtype == "checkbox" ? "Checkbox value" : "Dropdown Manual"}</span> <br /></>
                                                                : ""}

                                                            {radioAddFieldArr[index].map((element, index) => (
                                                                <div className="form-inline" key={index}>
                                                                    <input type="text" placeholder='Please enter option value' className='form-control' name="radioCheckboxValue" onChange={e => handleChangeRadio(index, e)} />
                                                                    {/* {
                                                                        index ?
                                                                            <BsFillTrash3Fill onClick={() => removeRadioCheckboxFormFields(index)} />
                                                                            : null
                                                                    } */}
                                                                </div>
                                                            ))}
                                                            <div className='col-md-1'>
                                                                <div className="button-section">
                                                                    <BsFilePlusFill onClick={() => addRadioCheckboxFormFields(index)} />
                                                                </div>
                                                            </div>

                                                        </div>
                                                        : ""}

                                                    {formValues[index].fieldtype == 'select' || formValues[index].fieldtype == "radio" ?
                                                        <div className='col-md-2'>
                                                            {index == 0 ?
                                                                <> <span>Select Form</span> <br /></>
                                                                : ""}
                                                            <select name='formId' className='form-control' onChange={e => handleChangeForNewField(index, e)}>
                                                                <option value={0}>Select Type</option>
                                                                {formList.map(item => (
                                                                    <option value={item.id}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        : ""}

                                                    {formValues[index].fieldtype == 'file' && formValues[index].fieldtypeId === '19' ?
                                                        <div className='col-md-2'>
                                                            {index == 0 ?
                                                                <> <span>Action</span> <br /></>
                                                                : ""}
                                                            <input type="file" className='form-control' name="action" onChange={e => handleAddFile(index, e)} />
                                                        </div>
                                                        :
                                                        ""}


                                                    {formValues[index].fieldtype == 'select' && formValues[index].formId > 0 ?
                                                        <div className='col-md-2'>
                                                            {index == 0 ?
                                                                <> <span>Select Field</span> <br /></>
                                                                : ""}
                                                            <select name='formType' className='form-control' onChange={e => handleChangeForNewField(index, e)}>
                                                                <option value={0}>Select Type</option>
                                                                {fieldArr[index]?.length == 0 || fieldArr[index] == undefined || fieldArr[index] == 'undefined' ?
                                                                    "" :
                                                                    fieldArr[index].map(itemDropdown => (
                                                                        <option value={itemDropdown.id}>{itemDropdown.fieldName}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                        : ""}

                                                    <div className='col-md-2'>
                                                        {index == 0 ?
                                                            <> <span>Mandatory</span> <br /></>
                                                            : ""}
                                                        <input type="checkbox" name="isMandatory" value={1} onChange={e => handleChangeForNewField(index, e)} />
                                                    </div>
                                                    {formValues[index].fieldtype == 'text' ?
                                                        <div className='col-md-2'>
                                                            {index == 0 ?
                                                                <> <span>Add More Fields</span> <br /></>
                                                                : ""}
                                                            <input type="checkbox" name="isAddMore" value={1} onChange={e => handleChangeForNewField(index, e)} />
                                                        </div> : ""}

                                                    <div className='col-md-1'>
                                                        {index == 0 ?
                                                            <> <span>Action</span> <br /></>
                                                            : ""}
                                                        {
                                                            index ?
                                                                <BsFillTrash3Fill onClick={() => removeFormFields(index)} />
                                                                : null
                                                        }
                                                        {

                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                        ))}
                                        <br />
                                        <div className="button-section">
                                            <Button variant="secondary-btn3" onClick={() => addFormFields()} className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6'>Add field</Button> &nbsp;&nbsp;&nbsp;&nbsp;
                                            <Button variant="secondary-btn3" type='submit' className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6'>Submit</Button>
                                        </div>
                                    </form>
                                </div>
                            </Col>
                        </Row>

                        {formValues.length > 0 ?
                            <Row>
                                <h5 className=''>Form Preview</h5>
                                <Col lg={12} md={12} sm={12} className="">
                                    <div className='formDiv'>
                                        <Form>
                                            <Row className="mb-4">
                                                {formValues.map(item => (
                                                    item.fieldName ?
                                                        <div className='col-md-6 mt-2'>
                                                            <Form.Group as={Col} controlId="formGridEmail">
                                                                <Form.Label>{item.fieldName}</Form.Label>

                                                                {item.fieldtype == 'select' ?
                                                                    <select name={item.fieldName} className='form-control' >
                                                                        <option>Select</option>
                                                                        <option>Example-1</option>
                                                                        <option>Example-2</option>
                                                                        <option>Example-3</option>
                                                                    </select>

                                                                    : item.fieldtype == 'textarea' ?
                                                                        <textarea name={item.fieldName} className='form-control' />

                                                                        : item.fieldtype == 'image' ?
                                                                            <input type="file" className='form-control' name={item.fieldName} />

                                                                            :

                                                                            item.fieldtype == 'radio' ?
                                                                                <>
                                                                                    <br />
                                                                                    Option-1 <input type='radio' /> <br />
                                                                                    Option-2 <input type='radio' /> <br />
                                                                                </>
                                                                                :
                                                                                item.fieldtype == 'checkbox' ?
                                                                                    <>
                                                                                        <br />
                                                                                        Option-1 <input type='checkbox' /> <br />
                                                                                        Option-2 <input type='checkbox' /> <br />
                                                                                    </>
                                                                                    :

                                                                                    item.fieldtype == 'btn' ?
                                                                                        <>
                                                                                            <br /> <button className='btn btn-primary'>{item.fieldName}</button>
                                                                                        </>
                                                                                        :

                                                                                        < Form.Control type={item.fieldtype} placeholder={`Enter ${item.fieldName}`} />
                                                                }
                                                            </Form.Group>
                                                        </div>
                                                        : ""
                                                ))
                                                }
                                            </Row>

                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                            : <span> No Preview Found!! </span>
                        }

                    </Container>
                </div>
            </div >

        </>
    )
}

export default EditWebForm;
