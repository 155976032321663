import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { commonService } from "../../services/common.services";
import { apiConstants } from "../../constants/api.constant";
// import { toast } from 'react-toastify';
import toast from 'react-hot-toast';
import Cookies from "js-cookie";

export const login = createAsyncThunk('user/login', async (data) => {
 
  const response = await commonService.withOutToken(apiConstants.ADMIN_LOGIN ,data); 
  if(response && response.data && response.data.success){
    toast.success(response.data.msg, {
      position: "top-right",
      autoClose: 2000,
    });
    Cookies.set("loginSuccessNeurulaHealth", JSON.stringify(response.data.data));
  }else{
    toast.error(
      response.data.msg, {
        position: "top-right",
      }
    )
  }
 
  return response.data; 
});

// SMS verification

export const smsVerification = createAsyncThunk('user/smsVerification', async (data) => {
 
  const response = await commonService.withOutToken(apiConstants.SMS_VERIFICATION ,data); 
  if(response && response.data && response.data.success){
    toast.success(response.data.msg, {
      position: "top-right",
      autoClose: 2000,
    });
  }else{
    toast.error(
      response.data.msg, {
        position: "top-right",
      }
    )
  }
 
  return response.data; 
});


// Get Staff Details 

export const getStaffDetails = createAsyncThunk('user/getStaffDetails', async (data) => {

  const response = await commonService.withToken(apiConstants.GET_STAFF_DETAILS ,data); 
  if(response && response.data && response.data.success){
    // toast.success(response.data.msg, {
    //   position: "top-right",
    //   autoClose: 2000,
    // });
  }else{
    toast.error(
      response.data.msg, {
        position: "top-right",
      }
    )
  }
 
  return response.data; 
});

const userSlice = createSlice({
  name: 'user',
  initialState: {
    loading: false,
    error: null,
    loggedInUser: null,
    smsVerificationData: null,
    getStaffDetailsData: null
  },
  reducers: {
    changeValue: (state, action) => {
      Object.assign(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.loggedInUser = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(smsVerification.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(smsVerification.fulfilled, (state, action) => {
        state.loading = false;
        state.smsVerificationData = action.payload;
      })
      .addCase(smsVerification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getStaffDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getStaffDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.getStaffDetailsData = action.payload;
      })
      .addCase(getStaffDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  },
});


export const { changeValue } = userSlice.actions;

export default userSlice.reducer 