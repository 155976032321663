import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import "../componentCss/webform.css";
import { Formik } from "formik";
import "../componentCss/sidebar.css";
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import config from "../../config/config";
import {
  emailSchema,
  otpSchema,
  resetPasswordSchema,
} from "../../validations/loginSchema";
import {
  forgotPasswordAction,
  verifyForgetPasswordOTPAction,
  resetpasswordAction,
} from "../../Action/admin.action";
import Cookies from "js-cookie";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [formData, setForm] = useState({
    email: "",
    otp: "",
  });
  const [step, setisStep] = useState(2);
  const [isLaoding, setisLaoding] = useState(0);
  const [otp, setotp] = useState(0);

  let handleSubmitForEmail = async (values) => {
    let res = await forgotPasswordAction(values);
    if (res.success) {
      toast.success(res.msg);
      setotp(res.data);
      setForm(values);
      setisStep(3);
    } else {
      setisLaoding(0);
      toast.error(res.msg);
    }
  };
  let handleSubmitForOtp = async (values) => {
    let res = await verifyForgetPasswordOTPAction(values);
    if (res.success) {
      toast.success(res.msg);
      setotp(res.data);
      setForm(values);
      setisStep(4);
    } else {
      setisLaoding(0);
      toast.error(res.msg);
    }
  };
  let handleSubmitForResetPassword = async (values) => {
    let res = await resetpasswordAction(values);
    if (res.success) {
      toast.success(res.msg);
      setTimeout(() => {
        navigate(`${config.baseUrl}login`);
      }, 2000);
    } else {
      setisLaoding(0);
      toast.error(res.msg);
    }
  };

  return (
    <>
      {/* <Toaster /> */}
      <div className="wrapper">
        <div id="content">
          <Container fluid className="p-4">
            {step == 2 ? (
              <Row className="loginForm">
                <Col lg={12} md={12} sm={12} className="">
                  <div className="formDiv">
                    <Formik
                      initialValues={formData}
                      validationSchema={emailSchema}
                      onSubmit={(values) => {
                        handleSubmitForEmail(values);
                      }}
                    >
                      {({
                        errors,
                        handleSubmit,
                        handleBlur,
                        handleChange,
                        touched,
                        values,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <Row className="col-md-12">
                            <div className="col-md-4"></div>
                            <div className="col-md-4 text-center">
                              <img
                                src={`${config.baseUrl}assets/images/logo2.png`}
                              />
                            </div>
                          </Row>

                          <Row className="col-md-12 mt-4">
                            <div className="col-md-4"></div>
                            <div className="col-md-4">
                              <Form.Label>Enter Email to get OTP</Form.Label>
                              <input
                                className="form-control"
                                type="email"
                                name="email"
                                onChange={handleChange}
                                placeholder="Please enter email"
                                onBlur={handleBlur}
                                value={values.email}
                              />

                              {errors.email && touched.email && (
                                <div className="text-danger">
                                  {errors.email}
                                </div>
                              )}
                            </div>
                          </Row>

                          <Row className="col-md-12 mt-3">
                            <div className="col-md-4"></div>
                            <div className="col-md-4">
                              {isLaoding ? (
                                <>
                                  <button
                                    disabled
                                    className="btn-sm btn btn-primary"
                                  >
                                    Loading{" "}
                                    <img
                                      height="20"
                                      width="20px"
                                      src="assets/images/loading.gif"
                                    />
                                  </button>
                                </>
                              ) : (
                                <button
                                  type="submit"
                                  className="btn-sm btn btn-primary"
                                >
                                  Get OTP{" "}
                                </button>
                              )}
                            </div>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </Col>
              </Row>
            ) : step == 4 ? (
              <Row className="loginForm">
                <Col lg={12} md={12} sm={12} className="">
                  <div className="formDiv">
                    <Formik
                      initialValues={formData}
                      validationSchema={resetPasswordSchema}
                      onSubmit={(values) => {
                        handleSubmitForResetPassword(values);
                      }}
                    >
                      {({
                        errors,
                        handleSubmit,
                        handleBlur,
                        handleChange,
                        touched,
                        values,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <Row className="col-md-12">
                            <div className="col-md-4"></div>
                            <div className="col-md-4 text-center">
                              <img
                                src={`${config.baseUrl}assets/images/logo2.png`}
                              />
                              <br />
                              <br />
                              <br />
                              <span>Reset Password</span>
                            </div>
                          </Row>

                          <Row className="col-md-12 mt-3">
                            <div className="col-md-4"></div>
                            <div className="col-md-4">
                              <Form.Label>Password</Form.Label>
                              <input
                                className="form-control"
                                type="password"
                                name="password"
                                onChange={handleChange}
                                placeholder="Please enter password"
                                onBlur={handleBlur}
                                value={values.password}
                              />

                              {errors.password && touched.password && (
                                <div className="text-danger">
                                  {errors.password}
                                </div>
                              )}
                            </div>
                          </Row>
                          <Row className="col-md-12 mt-3">
                            <div className="col-md-4"></div>
                            <div className="col-md-4">
                              <Form.Label> Confirm Password</Form.Label>
                              <input
                                className="form-control"
                                type="password"
                                name="confirmPassword"
                                onChange={handleChange}
                                placeholder="Please enter confirm Password"
                                onBlur={handleBlur}
                                value={values.confirmPassword}
                              />

                              {errors.confirmPassword &&
                                touched.confirmPassword && (
                                  <div className="text-danger">
                                    {errors.confirmPassword}
                                  </div>
                                )}
                            </div>
                          </Row>

                          <Row className="col-md-12 mt-3">
                            <div className="col-md-4"></div>
                            <div className="col-md-4">
                              {isLaoding ? (
                                <>
                                  <button
                                    disabled
                                    className="btn-sm btn btn-primary"
                                  >
                                    Loading{" "}
                                    <img
                                      height="20"
                                      width="20px"
                                      src="assets/images/loading.gif"
                                    />
                                  </button>
                                </>
                              ) : (
                                <button
                                  type="submit"
                                  className="btn-sm btn btn-primary"
                                >
                                  Reset
                                </button>
                              )}
                            </div>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </Col>
              </Row>
            ) : step == 3 ? (
              <Row className="loginForm">
                <Col lg={12} md={12} sm={12} className="">
                  <div className="formDiv">
                    <Formik
                      initialValues={formData}
                      validationSchema={otpSchema}
                      onSubmit={(values) => {
                        handleSubmitForOtp(values);
                      }}
                    >
                      {({
                        errors,
                        handleSubmit,
                        handleBlur,
                        handleChange,
                        touched,
                        values,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <Row className="col-md-12">
                            <div className="col-md-4"></div>
                            <div className="col-md-4 text-center">
                              <img
                                src={`${config.baseUrl}assets/images/logo2.png`}
                              />
                            </div>
                          </Row>
                          <br />
                          <br />

                          <Row className="col-md-12 mt-4">
                            <div className="col-md-4"></div>
                            <div className="col-md-4">
                              <Form.Label>
                                Please Check Your Email and enter Otp
                              </Form.Label>
                              <br />
                              <br />
                              <input
                                className="form-control"
                                type="text"
                                name="otp"
                                onChange={handleChange}
                                placeholder="Eg:85962"
                                onBlur={handleBlur}
                                value={values.otp}
                              />

                              {errors.otp && touched.otp && (
                                <div className="text-danger">{errors.otp}</div>
                              )}
                            </div>
                          </Row>

                          <Row className="col-md-12 mt-3">
                            <div className="col-md-4"></div>
                            <div className="col-md-4">
                              {isLaoding ? (
                                <>
                                  <button
                                    disabled
                                    className="btn-sm btn btn-primary"
                                  >
                                    Loading{" "}
                                    <img
                                      height="20"
                                      width="20px"
                                      src="assets/images/loading.gif"
                                    />
                                  </button>
                                </>
                              ) : (
                                <button
                                  type="submit"
                                  className="btn-sm btn btn-primary"
                                >
                                  Submit{" "}
                                </button>
                              )}
                            </div>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </Col>
              </Row>
            ) : (
              ""
            )}
          </Container>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
