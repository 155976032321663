import React, { useEffect, useState, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import ReactToPrint, { useReactToPrint } from 'react-to-print';

import Sidebar from '../sidebar';
import { Container } from 'react-bootstrap';
import Dashboardheader from '../dashboardheader';
import { getAppointmentList } from '../../../Action/admin.action';
import AppointmentDetailModal from '../ModalComponents/AppointmentDetailModal';
import DataTable from 'react-data-table-component';
import DateRangeModal from '../ModalComponents/DateRangeModal';

const transformScheduleDataToEvents = (schedule) => {
  return schedule.map((appointment) => {
    const { date, time, description, firstName, lastName } = appointment;
    const [hour, minute] = time.split(':');
    const eventDate = new Date(`${date}T${hour}:${minute}:00`);
    return {
      title: ` Appointment with ${firstName} ${lastName}`,
      start: eventDate,
      extendedProps: {
        description: description,
        doctorName: `${firstName} ${lastName}`,
        appointmentData: appointment
      }
    };
  });
};

const CalendarComponent = () => {
  const allDataRef = useRef(null);
  const [scheduleAppointmentList, setScheduleAppointmentList] = useState([]);
  const [filteredAppointments, setFilteredAppointments] = useState([]);

  const [show, setShow] = useState(false);
  const [showDateModal, setShowDateModal] = useState(false);
  const [eventDetails, setEventDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const events = transformScheduleDataToEvents(scheduleAppointmentList);

  useEffect(() => {
    getAppointment();
  }, []);

  const getAppointment = async () => {
    let res = await getAppointmentList();
    setIsLoading(false);
    if (res.success) {
      setScheduleAppointmentList(res.data);
    }
  };

  const handleEventClick = (clickInfo) => {
    setEventDetails(clickInfo.event);
    setShow(true);
  };

  const determineBackgroundColor = (eventDate) => {
    const today = new Date();
    if (eventDate < today) {
      return { backgroundColor: 'rgb(234, 67, 53)', color: '#ffff', borderRadius: '3px', padding: '4px' };
    } else if (eventDate.toDateString() === today.toDateString()) {
      return { backgroundColor: 'rgb(11, 128, 67)', color: '#ffff', borderRadius: '3px', padding: '4px' };
    } else {
      return { backgroundColor: 'rgb(11, 128, 67)', color: '#ffff', borderRadius: '3px', padding: '4px' };
    }
  };

  const renderEventContent = (eventInfo) => {
    const backgroundColor = determineBackgroundColor(new Date(eventInfo.event.start));
    return (
      <div style={backgroundColor}>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </div>
    );
  };

  const printColumns = [
    {
      name: '#',
      cell: (row, index, column, id) => {
        return (index + 1);
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Doctor Name",
      selector: (row) => `${row.firstName} ${row.lastName}`,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => `${row.date}`,
      sortable: true,
    },
    {
      name: "Time",
      selector: (row) => `${row.time}`,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => (row.description),
      sortable: true,
    },
  ];

  const handleShowModal = () => {
    setShowDateModal(!showDateModal);
  };

  const filterAppointments = (startDate, endDate) => {
    const filteredData = scheduleAppointmentList.filter((item) => {
      const itemDate = new Date(item.date);
      return itemDate >= startDate && itemDate <= endDate;
    });
    setFilteredAppointments(filteredData);
  };

  const handlePrint = useReactToPrint({
    documentTitle: 'Doctors Appointments',
    content: () => allDataRef.current,
  });

  const handleSelectDate = async ({ startDate, endDate }) => {
    await filterAppointments(startDate, endDate);
    handlePrint();
  };
  return (
    <>
      <Dashboardheader />
      <div className="wrapper">
        <Sidebar />
        <div id="content" className="calendar">
          <Container fluid className="p-4">
            <h1>Doctors Appointments</h1>
            <div className="calendar-main">
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                  left: 'prev,next today',
                  center: 'title',
                  right: 'dayGridMonth,timeGridWeek,timeGridDay printButton',
                }}
                customButtons={{
                  printButton: {
                    text: 'Print Appointments',
                    click: handleShowModal,
                  },
                }}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                eventClick={handleEventClick}
                initialView="dayGridMonth"
                events={events}
                eventContent={renderEventContent}
              />
            </div>
          </Container>
        </div>
      </div>
      {show && (
        <AppointmentDetailModal
          setShow={setShow}
          show={show}
          eventDetails={eventDetails}
          setScheduleAppointmentList={setScheduleAppointmentList}
        />
      )}
      {filteredAppointments.length > 0 && (
        <div style={{display:'none'}}>
          <div ref={allDataRef} style={{ padding: '20px' }}>
            <img src="assets/images/logo.png" alt="logo" className="mainLogo" />
            <center>
              <h2>Appointments List</h2>
            </center>
            <DataTable columns={printColumns} data={filteredAppointments} pagination={false} />
          </div>
        </div>
      )}
      <ReactToPrint
        trigger={() => (
          <button className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6 no-print">Print</button>
        )}
        content={() => allDataRef.current}
      />
      <DateRangeModal show={showDateModal} setShow={setShowDateModal} handleSelectDate={handleSelectDate} />
    </>
  );
};

export default CalendarComponent;
