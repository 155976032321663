import React from 'react';
import { Container, Button, Form, Nav, Navbar, NavDropdown, Offcanvas } from 'react-bootstrap';
import "../componentCss/header.css";
import { Link } from 'react-router-dom';
import config from '../../config/config';
import Cookies from 'js-cookie'
let loginData = (!Cookies.get('loginSuccessNeurulaHealth')) ? [] : JSON.parse(Cookies.get('loginSuccessNeurulaHealth'));

const Header = () => {
    return (
        <>
            <header>
                {['xl'].map((expand) => (
                    <Navbar key={expand} expand={expand} className="">
                        <Container >
                            <Navbar.Brand href="#">
                                <img src="assets/images/logo.png" alt="logo" className='mainLogo' />
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                            <Navbar.Offcanvas
                                id={`offcanvasNavbar-expand-${expand}`}
                                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                placement="end"
                            >
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                        Offcanvas
                                    </Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <Nav className="justify-content-center flex-grow-1 pe-3">
                                        <Nav.Link href="#">Resources</Nav.Link>
                                        <Nav.Link href="#">Services</Nav.Link>
                                        <Nav.Link href="#">Specialties</Nav.Link>
                                        <Nav.Link href="#">Solutions</Nav.Link>
                                        <Nav.Link href="#">Contact</Nav.Link>

                                    </Nav>
                                    <Form className="d-flex head-btn">
                                        {loginData?.id ?
                                            <Link to={`${config.baseUrl}${loginData.role == 2 || loginData.role == 1 ? 'dashboard' : 'staffDashboard'}`}>
                                                <Button variant="secondary-btn2" className='px-5'>Dashboard</Button>
                                            </Link>
                                            :
                                            <Link to={`${config.baseUrl}login`}>
                                                <Button variant="secondary-btn2" className='px-5'>Login</Button>
                                            </Link>
                                        }
                                    </Form>
                                </Offcanvas.Body>
                            </Navbar.Offcanvas>
                        </Container>
                    </Navbar>
                ))}
            </header>

            {/*-------------------- Header Section Exit ------------------ */}

        </>
    )
}

export default Header;
