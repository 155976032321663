import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Modal, Form } from "react-bootstrap";
import "../componentCss/webform.css";
import "../componentCss/sidebar.css";
import toast, { Toaster } from 'react-hot-toast';
import "react-toastify/dist/ReactToastify.css";
import Dashboardheader from "./dashboardheader";
import { Link } from "react-router-dom";
import config from "../../config/config";
import Sidebar from "./sidebar";

import DataTable from "react-data-table-component";
import {
  getStaffListAction,
  getFormListWithPermissionAction,
  updateFormsPermissionAction,
  getFormDataListAction,
  assignPatientAction,
  getAssignedPatientListAction,
  getAllPatientsCredentialsAction
} from "../../Action/admin.action";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { MdEdit } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { BsPersonAdd } from "react-icons/bs";
import { IoPerson, IoPrintSharp } from "react-icons/io5";
import { decryptData } from './decrypt';

const PatientCredentials = () => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isAccessAllPatient, setIsAccessAllPatient] = useState(0);
  const [staffList, setStaffList] = useState([]);
  const [patientList, setPatientList] = useState([]);
  const [formList, setFormList] = useState([]);
  const [isModelOpen, setIsOpen] = useState(false);
  const [staffId, setStaffId] = useState(0);
  const [isLaoding, setisLaoding] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isModelView, setIsModelView] = useState(false);
  const [isModelAssignPatient, setIsModelAssignPatient] = useState(false);
  const [viewData, setViewData] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [details, setDetails] = useState();
  const [selectedRow, setSelectedRow] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const allDataRef = useRef();
  const singleRowRef = useRef();

  const handlePageChange = (page) => {
    setCurrentPage(page - 1);
  };

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    setSelectedOptions(prev =>
      prev.includes(value) ? prev.filter(option => option !== value) : [...prev, value]
    );
  };

  const handleCheckboxChangeForAllPatient = (event) => {
    const value = event.target.value;
    setIsAccessAllPatient(isAccessAllPatient == 1 ? 0 : 1)
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage)
  };
  useEffect(() => {
    getStaffListAPI();
    getPatientsList()
  }, []);

  useEffect(() => {
    setSelectedRow(null);
  }, [currentPage]);

  let handleChange = (permissionType, formId) => {
    setFormList((prevForms) => {
      return prevForms.map((form) => {
        if (form.id === formId) {
          if (permissionType == "isAccess") {
            return {
              ...form,
              isAccess: !form.isAccess,
            };
          } else if (permissionType == "isEdit") {
            return {
              ...form,
              isEdit: !form.isEdit,
            };
          } else if (permissionType == "isDelete") {
            return {
              ...form,
              isDelete: !form.isDelete,
            };
          }
        }
        return form;
      });
    });
  };

  const handleClose = () => setIsOpen(false);
  const handleOpen = (id) => {
    setStaffId(id);
    getFormListAPI(id);
    setIsOpen(true);
  };

  const handleViewClose = () => setIsModelView(false);
  const handleOpenView = (data) => {
    setViewData(data);
    setIsModelView(true);
  };

  const handleOpenAssignPatient = async (data) => {
    let res = await getFormDataListAction({ 'id': 1 });
    if (res.success) {
      let data = decryptData(res.data?.records)
      setPatientList(data);
    }

    let assignedPatientList = await getAssignedPatientListAction({ 'staffId': data.id });
    if (assignedPatientList.success) {
      let data = decryptData(assignedPatientList.data)
      let recordIds = data.map(item => item.recordId.toString());
      setSelectedOptions(recordIds);
    }

    setViewData(data);
    setIsModelAssignPatient(true);
    setIsAccessAllPatient(data.isAccessAllPatient)
  };
  const handleViewCloseAssignPatient = () => {
    setIsModelAssignPatient(false);
    setSelectedOptions([]);
  }

  let getFormListAPI = async (userId) => {
    let res = await getFormListWithPermissionAction({ userId: userId });
    if (res.success) {
      setFormList(decryptData(res.data));
    }
  };

  let getStaffListAPI = async () => {
    let res = await getStaffListAction(details);
    setIsLoading(false);
    if (res.success) {
      let originalText = decryptData(res.data);
      setStaffList(originalText);
    }
  };
  let getPatientsList = async () => {
    let res = await getAllPatientsCredentialsAction();

    setIsLoading(false);
    if (res.statusCode) {
      let originalText = decryptData(res.data);
      setPatientList(originalText);
    }
  };

  let getStaffListAPIForFilter = async () => {
    setDetails("")
    let res = await getStaffListAction();

    if (res.success) {
      setStaffList(res.data);
    }
  };

  const searchByNameHandler = (e) => {
    const { name, value } = e.target;
    setDetails((old) => {
      return { ...old, [name]: value };
    });
  };
  let submitPermissionForm = async (e) => {
    e.preventDefault();
    setisLaoding(1);
    let res = await updateFormsPermissionAction({
      userId: staffId,
      formsPermission: formList,
    });
    if (res.success) {
      toast.success(res.msg);
      setTimeout(() => {
        setisLaoding(0);
        setIsOpen(false);
      }, 2000);
    } else {
      toast.error(res.msg);
    }
  };

  let assignPatientSubmit = async (e) => {
    e.preventDefault();
    if (selectedOptions.length == 0) {
      toast.error('Please select patient.');
      return;
    }
    let res = await assignPatientAction({ staffId: viewData.id, patientIds: selectedOptions, 'isAccessAllPatient': isAccessAllPatient });
    if (res.success) {
      toast.success(res.msg);
      setIsModelAssignPatient(false);
      setSelectedOptions([]);
    } else {
      toast.error(res.msg);
    }
  };

  const columns = [
    {
      name: "#",
      cell: (row, index, column, id) => {
        return index + 1 + currentPage * rowsPerPage;
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "User Name",
      selector: (row) => `${row.username}`,
      sortable: true,
      width: "15%"
    },
    {
      name: "staff Name",
      selector: (row) => `${row.firstName} ${row.lastName}`,
      sortable: true,
      width: "15%"
    },

    {
      name: "MRN Number",
      selector: (row) => row.MRNno,
      sortable: true,
      width: "12%"
    },

    {
      name: "Status",
      selector: (row) =>
        row.status == 1 ? (
          <>
            <div style={{ width: "auto" }}>
              <p className="text-primary mb-0">Active</p>
            </div>
          </>
        ) : (
          <>
            <div style={{ width: "auto" }}>
              <span className="text-danger">Inactive</span>
            </div>
          </>
        ),
      sortable: true,
      width: "10%"
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <Link to={`${config.baseUrl}edit-patientCredentials/${row.MRNno}`} title="Edit">
            <span ><MdEdit fill='#434544' size={20} /></span>
          </Link>
          &nbsp;
        </>
      ),
      sortable: true,
      width: "20%"

    },
  ];

  const printColumns = [
    {
      name: "#",
      cell: (row, index, column, id) => {
        return index + 1 + currentPage * 10;
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Name",
      selector: (row) => `${row.firstName} ${row.lastName}`,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Mobile Number",
      selector: (row) => row.mobileNumber,
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row.userGroup,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) =>
        row.isActive == 1 ? (
          <p className="text-primary">Active</p>
        ) : (
          <span className="text-danger">Inactive</span>
        ),
      sortable: true,
    },
  ];

  const printAllData = useReactToPrint({
    content: () => allDataRef.current,
  });

  const handlePrint = (row) => {
    setSelectedRow(row);
  };

  const printSingleRow = useReactToPrint({
    content: () => singleRowRef.current,
  });

  useEffect(() => {
    if (selectedRow) {
      printSingleRow();
    }
    setSelectedRow(null);
  }, [selectedRow, printSingleRow]);

  return (
    <>
      <Dashboardheader />
      <Toaster />
      <div className="wrapper">
        <Sidebar />
        <div id="content">
          <Container fluid className="p-4">
            <Row>
              <Col lg={12} md={12} sm={12} className="">
                <div className="innerhead mb-4">
                  <h5 className="headtitle">Patient Credentials</h5>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={12} sm={12} className="mt-3">
                <div style={{ float: "right" }}>
                  <Link to={`${config.baseUrl}add-patientCredentials`}>
                    <button className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6 mt-4">
                      Add+
                    </button>

                  </Link>
                </div>
                <div className="">
                  <div className="row">
                    <div className="col-md-4">
                      <label for="name">Search By:</label>&nbsp;
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        onChange={searchByNameHandler}
                      />
                    </div>

                    <div className="col-md-4">
                      <label for="cars">Search By Status</label> &nbsp; &nbsp;
                      <select
                        name="status"
                        id="cars"
                        className="form-control"
                        onChange={searchByNameHandler}
                      >
                        <option value="1">Active</option>
                        <option value="0">In active</option>
                      </select>
                    </div>

                    <div className="col-md-4">
                      <button
                        className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6 mt-4"
                        onClick={getStaffListAPIForFilter}
                      >
                        Reset
                      </button>
                      &nbsp;
                      <button
                        className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6 mt-4"
                        onClick={getStaffListAPI}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
                <br />
                <ReactToPrint
                  trigger={() => <button className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6 no-print'>Print</button>}
                  content={printAllData}
                />
                <div className="maintable table-responsive">
                  <h5 className="">Patient List </h5>
                  {isLoading ? (
                    <div style={{ textAlign: "center" }}>
                      <img
                        style={{ width: "40px" }}
                        src="assets/images/loading.gif"
                      />
                    </div>
                  ) : (
                    <DataTable
                      columns={columns}
                      data={patientList}
                      pagination
                      paginationPerPage={rowsPerPage}
                      paginationRowsPerPageOptions={[10, 15, 25, 50, 100]}
                      paginationComponentOptions={{
                        rowsPerPageText: 'Row per page:',
                        rangeSeparatorText: 'out of',
                      }}
                      onChangePage={handlePageChange}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                    />

                  )}
                </div>
                {/*------------------------------- Section For Showing All Data For Print--------------------------------------------------- */}

                <div style={{ display: 'none' }}>
                  <div ref={allDataRef}>
                    <center>
                      <h2 className=''> Staff Management List</h2>
                    </center>
                    <DataTable
                      columns={printColumns}
                      data={staffList}
                      pagination={false}
                    />
                  </div>
                </div>
                {/*------------------------------- Section For Showing Single Data For Print--------------------------------------------------- */}

                <div style={{ display: 'none' }}>
                  <div className="card-body" ref={singleRowRef} style={{ padding: '20px', border: '1px solid #ccc', borderRadius: '10px', margin: '20px', fontFamily: 'Arial, sans-serif' }}>
                    {selectedRow && (
                      <div style={{ textAlign: 'left', lineHeight: '1.5' }}>
                        <h2 className="card-title" style={{ textAlign: 'center', marginBottom: '20px' }}>Staff Management List</h2>
                        <p><strong>ID:</strong> {selectedRow.id}</p>
                        <p><strong>First Name:</strong> {selectedRow.firstName}</p>
                        <p><strong>Last Name:</strong> {selectedRow.lastName}</p>
                        <p><strong>City:</strong> {selectedRow.city}</p>
                        <p><strong>Address:</strong> {selectedRow.address}</p>
                        <p><strong>Designation:</strong> {selectedRow.designation}</p>
                        <p><strong>Employee Id:</strong> {selectedRow.employeeId}</p>
                        <p><strong>Gender:</strong> {selectedRow.gender}</p>
                        <p><strong>Date Of Joining:</strong> {selectedRow.DateOfJoining}</p>
                        <p><strong>Licence Number:</strong> {selectedRow.licenceNumber}</p>
                        <p><strong>Mobile Number:</strong> {selectedRow.mobileNumber}</p>
                        <p><strong>User Group:</strong> {selectedRow.userGroup}</p>
                        <p><strong>Image:</strong>
                          {selectedRow.photo ? (
                            <>
                              <img width="100" src={selectedRow.photo}></img>
                              <br />
                            </>
                          ) : (
                            ""
                          )}</p>
                        <p><strong>Signature:</strong>
                          {selectedRow.signature ? (
                            <>
                              <img width="100" src={selectedRow.signature}></img>
                              <br />
                            </>
                          ) : (
                            ""
                          )}
                        </p>
                        <p><strong>Stamp Image:</strong>
                          {selectedRow.stampImage ? (
                            <>
                              <img width="100" src={selectedRow.stampImage}></img>
                              <br />
                            </>
                          ) : (
                            ""
                          )}
                        </p>
                        <p><strong>Status:</strong> {selectedRow.status == 1 ? 'Active' : 'Inactive'}</p>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>


            <Modal
              show={isModelOpen}
              onHide={() => handleClose(false)}
              dialogClassName="modal-90w modal-right"
              aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <b>Permissions</b>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Row className="mb-4">
                    <div className="col-md-6">
                      <Form.Label>
                        <b> Form Name </b>
                      </Form.Label>{" "}
                      <br />
                    </div>

                    <div className="col-md-6">
                      <Form.Label>
                        <b>Access</b>
                      </Form.Label>
                    </div>

                    {/* <div className="col-md-2">
                    <Form.Label>
                      <b> Edit </b>
                    </Form.Label>
                  </div>

                  <div className="col-md-2">
                    <Form.Label>
                      {" "}
                      <b> Delete </b>
                    </Form.Label>
                  </div> */}
                  </Row>
                  <hr />

                  {formList.length > 0
                    ? formList.map((data) => (
                      <>
                        <Row className="mb-4">
                          <div className="col-md-6">{data.name}</div>

                          <div className="col-md-6">
                            <input
                              type="checkbox"
                              name="isAccess"
                              onChange={() =>
                                handleChange("isAccess", data.id)
                              }
                              checked={data.isAccess == 1 ? "checked" : ""}
                            />
                          </div>

                          {/* <div className="col-md-2">
                          <input
                            type="checkbox"
                            name="isEdit"
                            onChange={() => handleChange("isEdit", data.id)}
                            checked={data.isEdit == 1 ? "checked" : ""}
                          />
                        </div>

                        <div className="col-md-2">
                          <input
                            type="checkbox"
                            name="isDelete"
                            onChange={() =>
                              handleChange("isDelete", data.id)
                            }
                            checked={data.isDelete == 1 ? "checked" : ""}
                          />
                        </div> */}
                        </Row>
                        <hr />
                      </>
                    ))
                    : ""}

                  <Row className="mb-4">
                    <Form.Group as={Col}>
                      {isLaoding ? (
                        <>
                          <button
                            disabled
                            className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6"
                          >
                            Loading{" "}
                            <img
                              height="20"
                              width="20px"
                              src="assets/images/loading.gif"
                            />
                          </button>
                        </>
                      ) : (
                        <button
                          type="submit"
                          onClick={submitPermissionForm}
                          className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6"
                        >
                          Submit
                        </button>
                      )}
                    </Form.Group>
                  </Row>
                </Form>
              </Modal.Body>
            </Modal>

            {/* Assign form to staff */}
            <Modal
              show={isModelView}
              onHide={() => handleViewClose(false)}
              dialogClassName="modal-90w modal-right modal-lg"
              aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <b>View Staff Details</b>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>

                <ul className="list-group">
                  <li className="list-group-item d-flex flex-row " >
                    <div className="w-50">
                      <strong className="staffView w-50">First Name:</strong>{" "}
                    </div>

                    {viewData?.namePrefix}{" "}
                    {viewData?.firstName ? viewData?.firstName : "NA"}
                  </li>
                  <li className="list-group-item d-flex flex-row "  >
                    <div className="w-50">
                      <strong className="staffView">Middle Name:</strong>{" "}
                    </div>

                    {viewData?.middleName ? viewData?.middleName : "NA"}
                  </li>
                  <li className="list-group-item d-flex flex-row">

                    <div className="w-50">
                      <strong className="staffView">Last Name:</strong>{" "}
                    </div>

                    {viewData?.lastName ? viewData?.lastName : "NA"}
                  </li>

                  <li className="list-group-item d-flex flex-row">
                    <div className="w-50">
                      <strong className="staffView">Employee ID:</strong>{" "}
                    </div>

                    {viewData?.employeeId ? viewData?.employeeId : "NA"}
                  </li>

                  <li className="list-group-item d-flex flex-row">
                    <div className="w-50">
                      <strong className="staffView">Email:</strong>{" "}
                    </div>

                    {viewData?.email ? viewData?.email : "NA"}
                  </li>

                  <li className="list-group-item d-flex flex-row">
                    <div className="w-50">
                      <strong className="staffView">Mobile Number:</strong>{" "}
                    </div>

                    {viewData?.mobileNumber ? viewData?.mobileNumber : "NA"}
                  </li>

                  <li className="list-group-item d-flex flex-row">
                    <div className="w-50">
                      <strong className="staffView">City:</strong>{" "}
                    </div>

                    {viewData?.city ? viewData?.city : "NA"}
                  </li>

                  <li className="list-group-item d-flex flex-row">
                    <div className="w-50">
                      <strong className="staffView">Address:</strong>{" "}
                    </div>

                    {viewData?.address ? viewData?.address : "NA"}
                  </li>

                  <li className="list-group-item d-flex flex-row">
                    <div className="w-50">
                      <strong className="staffView">State:</strong>{" "}
                    </div>
                    {viewData?.state ? viewData?.state : "NA"}
                  </li>

                  <li className="list-group-item d-flex flex-row">
                    <div className="w-50">
                      <strong className="staffView">Emirates ID number:</strong>{" "}
                    </div>
                    {viewData?.emiratesIdNumber
                      ? viewData?.emiratesIdNumber
                      : "NA"}
                  </li>

                  <li className="list-group-item d-flex flex-row">
                    <div className="w-50">
                      <strong className="staffView">Gender:</strong>{" "}
                    </div>
                    {viewData?.gender == 1
                      ? "Male"
                      : viewData?.gender == 2
                        ? "Female"
                        : "Other"}
                  </li>

                  <li className="list-group-item d-flex flex-row">
                    <div className="w-50">
                      <strong className="staffView">Designation:</strong>{" "}
                    </div>
                    {viewData?.designation ? viewData?.designation : "NA"}
                  </li>

                  <li className="list-group-item d-flex flex-row">
                    <div className="w-50">
                      <strong className="staffView">Role:</strong>{" "}
                    </div>

                    {viewData?.userGroup ? viewData?.userGroup : "NA"}
                  </li>

                  <li className="list-group-item d-flex flex-row">
                    <div className="w-50">
                      <strong className="staffView">Stamp Image:</strong>
                    </div>
                    {viewData.stampImage ? (
                      <>
                        <img width="100" src={viewData.stampImage}></img>
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                  </li>

                  <li className="list-group-item d-flex flex-row">
                    <div className="w-50">
                      <strong className="staffView">Photo:</strong>
                    </div>
                    {viewData.photo ? (
                      <>
                        <img width="100" src={viewData.photo}></img>
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                  </li>

                  <li className="list-group-item d-flex flex-row">
                    <div className="w-50">
                      <strong className="staffView">Signature:</strong>
                    </div>
                    {viewData.signature ? (
                      <>
                        <img width="100" src={viewData.signature}></img>
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                  </li>

                  <li className="list-group-item d-flex flex-row">
                    <div className="w-50">
                      <strong className="staffView">Date of joining:</strong>
                    </div>
                    {viewData?.DateOfJoining ? viewData?.DateOfJoining : "NA"}
                  </li>

                  <li className="list-group-item d-flex flex-row">
                    <div className="w-50">
                      <strong className="staffView">License Number:</strong>{" "}
                    </div>

                    {viewData?.licenceNumber ? viewData?.licenceNumber : "NA"}
                  </li>
                </ul>
              </Modal.Body>
            </Modal>

            {/* Assign patient to staff */}
            <Modal
              show={isModelAssignPatient}
              onHide={() => handleViewCloseAssignPatient(false)}
              dialogClassName="modal-90w modal-right modal-lg"
              aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <b>Assign Patient</b>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>

                <ul className="list-group">
                  <li className="list-group-item d-flex flex-row " >
                    <div className="w-50">
                      <strong className="staffView w-50">Staff Name:</strong>{" "}
                    </div>

                    {viewData?.namePrefix}{" "}
                    {viewData?.firstName ? viewData?.firstName : "NA"}
                  </li>

                  <li className="list-group-item d-flex flex-row " >
                    <div className="w-50">
                      <strong className="staffView w-50">Is access all patient:</strong>
                    </div>
                    <input
                      type="checkbox"
                      value="1"
                      checked={isAccessAllPatient}
                      onChange={handleCheckboxChangeForAllPatient}
                    />
                  </li>

                  {isAccessAllPatient == 0 &&
                    <>
                      <li className="list-group-item d-flex flex-row " >
                        <div className="w-50">
                          <strong className="staffView w-50">Patient List:</strong>{" "}
                        </div>
                        <ul style={{ listStyleType: 'none', padding: 0, height: '300px', overflowX: 'auto' }}>
                          {patientList.map(data => (
                            <li key={data.recordId}>
                              <label>
                                <input
                                  type="checkbox"
                                  value={data.recordId}
                                  checked={selectedOptions.includes(data.recordId)}
                                  onChange={handleCheckboxChange}
                                />
                                &nbsp; {`${data[1] ? data[1] : ''} ${data[2] ? data[2] : ''}(${data[1929] ? data[1929] : 'NA'})`}
                              </label>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </>
                  }
                  <li className="list-group-item d-flex flex-row " >
                    <div className="w-50">
                      <button className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6" onClick={assignPatientSubmit}>Assign</button>
                    </div>
                  </li>
                </ul>
              </Modal.Body>
            </Modal>
          </Container>
        </div>
      </div>
    </>
  );
};

export default PatientCredentials;
