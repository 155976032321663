import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import "../componentCss/webform.css";
import "../componentCss/sidebar.css";
import {
  getFormListAction,
  deleteWebFormAction,
  getDepartmentListAction,
  submitWebFormActionData
} from "../../Action/admin.action";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import config from "../../config/config";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import toast, { Toaster } from 'react-hot-toast';

import ReactToPrint, { useReactToPrint } from "react-to-print";
import { MdDelete, MdEdit } from "react-icons/md";
import { BsToggleOn, BsToggleOff, BsSignIntersectionFill } from "react-icons/bs";
import { IoLayersSharp } from "react-icons/io5";
import { FaEdit, FaEye } from "react-icons/fa";
import { decryptData } from './decrypt';

const Webform = () => {
  const [formList, setFormList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchBy, setSearchBy] = useState('');
  const [searchByDepartment, setSearchByDepartment] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [departmentList, setDepartmentList] = useState([])
  const [formActionData, setformActionData] = useState('')
  const [isModelView, setIsModelView] = useState(false);

  const allDataRef = useRef()

  const handlePageChange = page => {
    setCurrentPage(page - 1);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage)
  };

  let getDepartmentList = async () => {
    let res = await getDepartmentListAction();
    if (res.success) {
      setDepartmentList(decryptData(res.data));
    }
  }

  const handleViewClose = () => {
    setIsModelView(false);
  }

  const handleOpenView = (data) => {
    setformActionData(data)
    setIsModelView(true);
  };

  useEffect(() => {
    getFormListAPI();
    getDepartmentList()
  }, []);

  let getFormListAPI = async (searchBy = '') => {

    let res = await getFormListAction({ 'searchBy': searchBy, 'searchByDepartment': searchByDepartment });
    if (res.success) {
      let originalText = decryptData(res.data);
      setFormList(originalText);
    } else {
      setFormList([]);
    }
  };

  const inputHandler = (e) => {
    const { value } = e.target;
    setSearchBy(value)
  };

  const handleChange = (e) => {
    setSearchByDepartment(e.target.value)
  };

  useEffect(() => {
    if (searchBy === '' && searchByDepartment === '') {
      getFormListAPI();
    }
  }, [searchBy, searchByDepartment]);


  let resetFilter = async () => {
    setSearchBy('');
    setSearchByDepartment('');
  };

  const inputHandlerAction = (e) => {
    const { name, value } = e.target;
    setformActionData((old) => {
      return { ...old, [name]: value };
    });
  };

  let filterSubmit = async () => {
    getFormListAPI(searchBy);
  };

  let submitActionButton = async () => {
    let res = await submitWebFormActionData(formActionData);
    if (res.success) {
      toast.success(res.msg)
      getFormListAPI()
      setIsModelView(false);
    } else {
      toast.error(res.msg)
    }
  };

  const columns = [
    {
      name: '#',
      cell: (row, index, column, id) => {
        return (index + 1) + (currentPage * rowsPerPage);
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Name",
      selector: (row) => `${row.name}`,
      sortable: true,
    },
    {
      name: "Department",
      selector: (row) => `${row.departmentName}`,
      sortable: true,
    },
    {
      name: "Form Number",
      selector: (row) => `${row.formNumber}`,
      sortable: true,
    },
    {
      name: "Late Entry Access",
      selector: (row) => (
        <>
          {row.isLateEntry ?
            `Yes(${row.lateEntryTime} Hrs)`
            : 'No'
          }
        </>
      ),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (
        <>
          {row.status == 1 ? 'Active' : 'Deactive'}
        </>
      ),
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <Link to={`${config.baseUrl}formDetails/` + row.id}>
            <span> <IoLayersSharp title="Structure" fill='#434544' size={24} /></span>
          </Link>
          &nbsp;
          &nbsp;
          {" "}
          &nbsp;
          <Link to={`${config.baseUrl}editWebForm/` + row.id}>
            <span ><MdEdit fill='#434544' title="Edit" size={24} /></span>
          </Link>
          &nbsp;
          &nbsp;
          <span style={{ cursor: 'pointer' }} onClick={() => deleteForm(row)}>
            {row.status == 1 ?
              <BsToggleOn fill='#434544' title="Active" size={24} />
              :
              <BsToggleOff fill='#434544' title="Deactive" size={24} />
            }
          </span>
          &nbsp;
          &nbsp;
          <span >
            <Link onClick={() => { handleOpenView(row) }}
            >
              <BsSignIntersectionFill style={{ cursor: 'pointer' }} fill='#434544' title="Form Actions" size={24} />
            </Link>
          </span>
        </>
      ),
      sortable: true,

    },
  ];


  const deleteForm = async (data) => {
    let msg = ``;
    let status = 0;
    if (data.status == 0) {
      msg = `You want to active this form?`
      status = 1
    } else {
      msg = `You want to deactive this form?`
      status = 0
    }

    Swal.fire({
      title: "Are you sure?",
      text: msg,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Submit",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deleteWebFormAction({ 'id': data.id, 'status': status });
        if (res.success) {
          getFormListAPI();
          toast.success(res.msg);
        } else {
          toast.error(res.msg);
        }
      }
    });
  };

  const printcolumns = [
    {
      name: '#',
      cell: (row, index, column, id) => {
        return (index + 1) + (currentPage * 10);
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Name",
      selector: (row) => `${row.name}`,
      sortable: true,
    },
    {
      name: "Department",
      selector: (row) => `${row.departmentName}`,
      sortable: true,
    },
    {
      name: "Form Number",
      selector: (row) => `${row.formNumber}`,
      sortable: true,
    },
    {
      name: "Sidebar Access",
      selector: (row) => (row.isSideBarAccess == 1 ? "Yes" : "No"),
      sortable: true,
    },

  ];

  const printAllData = useReactToPrint({
    content: () => allDataRef.current,
  });
  return (
    <>
      <Container fluid className="p-4">
        <Row>
          <Col lg={12} md={12} sm={12} className="">
            <div className="innerhead mb-4">
              <h5 className="headtitle">Web Forms</h5>
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={12} className="mt-3">
            <div style={{ float: "right" }}>
              <Link to={`${config.baseUrl}createWebForm`}>
                <button className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6">
                  Create New Form
                </button>
              </Link>
            </div>

            <div className="row">
              <div className="col-md-4">
                <label for="name">Search By:</label>&nbsp;
                <input
                  type="text"
                  id="name"
                  name="searchBy"
                  className="form-control"
                  placeholder="Enter form name"
                  value={searchBy}
                  onChange={inputHandler}
                />
              </div>
              <div className="col-md-4">
                <label for="name">Search By Department:</label>&nbsp;
                <select name='searchByDepartment'
                  value={searchByDepartment}
                  className='form-control'
                  onChange={handleChange}>
                  <option value={''}>-Select-</option>
                  {departmentList.map(data => (
                    <option key={data.id} value={data.id}>{data.name}</option>
                  ))}
                </select>

              </div>


              <div className="col-md-4">
                <button
                  className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6 mt-4"
                  onClick={resetFilter}
                >
                  Reset
                </button>
                &nbsp;
                <button
                  className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6 mt-4"
                  onClick={filterSubmit}
                >
                  submit
                </button>
                &nbsp;
                <ReactToPrint
                  trigger={() => <button className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6 no-print'>Print</button>}
                  content={printAllData}
                />
              </div>

            </div>


            {formList.length > 0 ?
              <div className="maintable table-responsive">
                <h5 className="">Forms List </h5>
                <DataTable
                  columns={columns}
                  data={formList}
                  pagination
                  paginationPerPage={rowsPerPage}
                  paginationRowsPerPageOptions={[10, 15, 25, 50, 100]}
                  paginationComponentOptions={{
                    rowsPerPageText: 'Row per page:',
                    rangeSeparatorText: 'out of',
                  }}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                />
              </div>
              :
              <p className='text-center mt-5'>
                <img src='assets/images/no-image.png' />
              </p>
            }
            {/*------------------------------- Section For Showing All Data For Print--------------------------------------------------- */}

            <div style={{ display: 'none' }}>
              <div ref={allDataRef}>
                <img src="assets/images/logo.png" alt="logo" className='mainLogo' />
                <center>
                  <h2 className=''> Form List</h2>
                </center>
                <DataTable
                  columns={printcolumns}
                  data={formList}
                  pagination={false}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {/* </div> */}
      {/* </div> */}

      <Modal
        show={isModelView}
        onHide={() => handleViewClose(false)}
        dialogClassName="modal-90w modal-right modal-lg"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <b>Form Actions</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="row mt-3">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <label for="name">Template</label>&nbsp;
                <select className="form-control" onChange={inputHandlerAction} name="isTemplate" value={formActionData.isTemplate}>
                  <option value={0}>No</option>
                  <option value={1}>Yes</option>
                </select>
              </div>
              <div className="col-md-2"></div>
            </div>

            <div className="row mt-3">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <label for="name">Invalidate</label>&nbsp;
                <select className="form-control" onChange={inputHandlerAction} name="isInvalidate" value={formActionData.isInvalidate}>
                  <option value={0}>No</option>
                  <option value={1}>Yes</option>
                </select>
              </div>
              <div className="col-md-2"></div>
            </div>

            <div className="row mt-3">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <label for="name">Addendum</label>&nbsp;
                <select className="form-control" onChange={inputHandlerAction} name="isAddendum" value={formActionData.isAddendum}>
                  <option value={0}>No</option>
                  <option value={1}>Yes</option>
                </select>
              </div>
              <div className="col-md-2"></div>
            </div>

            <div className="row mt-3">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <label for="name">Review/Acknowledgement</label>&nbsp;
                <select className="form-control" onChange={inputHandlerAction} name="isAcknowledgement" value={formActionData.isAcknowledgement}>
                  <option value={0}>No</option>
                  <option value={1}>Yes</option>
                </select>
              </div>
              <div className="col-md-2"></div>
            </div>

            <div className="row mt-3">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <button className="btn btn-primary" onClick={submitActionButton}>Submit</button>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default Webform;
