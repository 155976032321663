import { getRequest, postRequest, postRequestFormData, postRequestFormDataWithImage, postrequestFormDataForImage } from "../config/helper";

export const loginAction = (data) => {
   return postRequest('adminLogin', data).then(res => { return res.data })
}
export const smsVerificationAction = (data) => {
   return postRequest('smsVerification', data).then(res => { return res.data })
}
export const forgotPasswordAction = (data) => {
   return postRequest('forgotPassword', data).then(res => { return res.data })
}
export const verifyForgetPasswordOTPAction = (data) => {
   return postRequest('verifyForgetPasswordOTP', data).then(res => { return res.data })
}
export const resetpasswordAction = (data) => {
   return postRequest('resetpassword', data).then(res => { return res.data })
}

export const getStaffListAction = (data) => {
   return postRequest('getStaffList', data).then(res => { return res.data })
}
export const getAllPatientsCredentialsAction = (data) => {
   return getRequest('getAllPatientsCredentials', data).then(res => { return res.data })
}

export const getGroupTypesAction = (data) => {
   return getRequest('getUserGroup', data).then(res => { return res.data })
}

export const addStaffAction = (data) => {
   return postRequestFormData('addStaff', data).then(res => { return res.data })
}
export const addPatientAction = (data) => {
   return postRequest('addPatients', data).then(res => { return res.data })
}

export const updateFcmTokenAction = (data) => {
   return postRequestFormData('updateFcmToken', data).then(res => { return res.data })
}

export const updateStaffAction = (data) => {
   return postRequestFormData('updateStaff', data).then(res => { return res.data })
}

export const updatePatientCredentialsAction = (data) => {
   return postRequestFormData('updatePatientCredentials', data).then(res => { return res.data })
}


export const changePasswordAction = (data) => {
   return postRequestFormData('changePassword', data).then(res => { return res.data })
}

export const getStaffDetailsAction = (data) => {
   return postRequest('getStaffListById', data).then(res => { return res.data })
}

export const getpatientDetailsbyIdAction = (data) => {
   return postRequestFormData('getPatientDetailById', data).then(res => { return res.data })
}

export const getUserGroupListAction = (data) => {
   return getRequest('getUserGroupList', data).then(res => { return res.data })
}

export const addUserGroupAction = (data) => {
   return postRequest('addUserGroup', data).then(res => { return res.data })
}

export const getGroupDetailsAction = (data) => {
   return postRequest('getUserGroupById', data).then(res => { return res.data })
}

export const updateUserGroupAction = (data) => {
   return postRequest('updateUserGroup', data).then(res => { return res.data })
}

export const getFormListWithPermissionAction = (data) => {
   return postRequest('getFormListWithPermission', data).then(res => { return res.data })
}

export const updateFormsPermissionAction = (data) => {
   return postRequest('updateFormsPermission', data).then(res => { return res.data })
}

export const getGroupListWithPermissionAction = (data) => {
   return postRequest('getGroupListWithPermission', data).then(res => { return res.data })
}

export const updateGroupsPermissionAction = (data) => {
   return postRequest('updateGroupsPermission', data).then(res => { return res.data })
}

export const insertFormDataAction = (data) => {
   return postrequestFormDataForImage('insertFormData', data).then(res => { return res.data })
}
export const insertFormDataActionForSubForm = (data) => {
   return postRequest('updateSubFormData', data).then(res => { return res.data })
}

export const getPatientDataAction = (data) => {
   return postRequest('getPatientData', data).then(res => { return res.data })
}

export const getPatientDataByMRNAction = (data) => {
   return postRequest('getPatientDataByMrn', data).then(res => { return res.data })
}

export const saveWebFormAction = (data) => {
   return postRequest('saveWebForm', data).then(res => { return res.data })
}

export const getFormListAction = (data) => {
   return getRequest('getFormList', data).then(res => { return res.data })
}

export const getFormListForStaffAction = (data) => {
   return getRequest('getFormListForStaff', data).then(res => { return res.data })
}

export const getFormListForMenuAction = (data) => {
   return getRequest('getFormListForMenu', data).then(res => { return res.data })
}

export const getFormDetailsAction = (data) => {
   return postRequest('getFormDetails', data).then(res => { return res.data })
}

export const getFormFieldsDataAction = (data) => {
   return postRequest('getFormFieldsData', data).then(res => { return res.data })
}

export const getFieldTypeAction = (data) => {
   return getRequest('getFieldTypeList', data).then(res => { return res.data })
}

export const getDepartmentAction = (data) => {
   return getRequest('getDepartmentList', data).then(res => { return res.data })
}

export const getCategoryListAction = (data) => {
   return getRequest('getCategoryList', data).then(res => { return res.data })
}

export const getCategoryListForWebFormAction = (data) => {
   return getRequest('getCategoryListForWebForm', data).then(res => { return res.data })
}

export const getDepartmentMenuesAction = (data) => {
   return getRequest('getDepartmentMenues', data).then(res => { return res.data })
}

export const getDepartmentFormsAction = (data) => {
   return postRequest('getDepartmentForms', data).then(res => { return res.data })
}

export const getDepartmentFormsTypeAction = (data) => {
   return postRequest('getDepartmentFormsType', data).then(res => { return res.data })
}

export const addCategoryAction = (data) => {
   return postRequest('addCategory', data).then(res => { return res.data })
}

export const getFormCategoryAction = (data) => {
   return postRequest('getFormCategory', data).then(res => { return res.data })
}

export const updateCategoryAction = (data) => {
   return postRequest('updateCategory', data).then(res => { return res.data })
}

export const getFormTypeListAction = (data) => {
   return postRequest('getFormTypeList', data).then(res => { return res.data })
}

export const getFormDataListAction = (data) => {
   return postRequest('getFormDataList', data).then(res => { return res.data })
}

export const getPatientDetailsAction = (data) => {
   return postRequest('getPatientDetails', data).then(res => { return res.data })
}

export const getFormDataListByStaffAction = (data) => {
   return postRequest('getFormDataListByStaff', data).then(res => { return res.data })
}

export const getPatientListAction = (data) => {
   return postRequest('getPatientList', data).then(res => { return res.data })
}

export const getStaffActivityLogsListAction = (data) => {
   return postRequest('getStaffActivityLogsList', data).then(res => { return res.data })
}

export const assignPatientAction = (data) => {
   return postRequest('assignPatient', data).then(res => { return res.data })
}

export const getAssignedPatientListAction = (data) => {
   return postRequest('getAssignedPatientList', data).then(res => { return res.data })
}

export const updateStaffStatusAction = (data) => {
   return postRequest('updateStaffStatus', data).then(res => { return res.data })
}


export const getUpcomingEventAction = (data) => {
   return postRequest('getUpcomingEvent', data).then(res => { return res.data })
}

export const getPatientRecordDashboardAction = (data) => {
   return postRequest('getPatientRecordDashboard', data).then(res => { return res.data })
}



export const getFormDataListDetailByIdAction = (data) => {
   return postRequest('getFormDataListDetailById', data).then(res => { return res.data })
}

export const getDashboardStatisticsAction = (data) => {
   return getRequest('getDashboardStatistics', data).then(res => { return res.data })
}

export const updateWebFormAction = (data) => {
   return postRequest('updateWebForm', data).then(res => { return res.data })
}

export const addWebFormFieldsAction = (data) => {
   return postRequestFormDataWithImage('addWebFormFields', data).then(res => { return res.data })
}

export const deleteWebFormFieldAction = (data) => {
   return postRequest('deleteWebFormField', data).then(res => { return res.data })
}

export const deleteWebFormAction = (data) => {
   return postRequest('deleteWebForm', data).then(res => { return res.data })
}

export const getDepartmentListAction = (data) => {
   return getRequest('getAllDepartmentList', data).then(res => { return res.data })
}

export const submitWebFormActionData = (data) => {
   return postRequest('webFormActionData', data).then(res => { return res.data })
}

export const addDepartmentAction = (data) => {
   return postRequest('addDepartment', data).then(res => { return res.data })
}

export const getDepartmentDetailsAction = (data) => {
   return postRequest('getDepartmentDetails', data).then(res => { return res.data })
}

export const updateDepartmentAction = (data) => {
   return postRequest('updateDepartment', data).then(res => { return res.data })
}

export const getDepartmentFormsListAction = (data) => {
   return getRequest('getDepartmentFormsList', data).then(res => { return res.data })
}

export const addDepartmentFormsAction = (data) => {
   return postRequest('addDepartmentForms', data).then(res => { return res.data })
}

export const getDepartmentFormDetailsAction = (data) => {
   return postRequest('getDepartmentFormDetails', data).then(res => { return res.data })
}

export const updateDepartmentFormAction = (data) => {
   return postRequest('updateDepartmentForm', data).then(res => { return res.data })
}

export const getCPTMasterListAction = (data) => {
   return getRequest('getCPTMasterList', data).then(res => { return res.data })
}

export const addCPTMasterAction = (data) => {
   return postRequest('addCPTMaster', data).then(res => { return res.data })
}

export const deleteCPTMasterDataAction = (data) => {
   return postRequest('deleteCPTMasterData', data).then(res => { return res.data })
}

export const getDrugMasterListAction = (data) => {
   return getRequest('getDrugMasterList', data).then(res => { return res.data })
}

export const getHCPCSMasterListAction = (data) => {
   return getRequest('getHcpcsMasterList', data).then(res => { return res.data })
}

export const addHPCSMasterAction = (data) => {
   return postRequest('addHPCSMaster', data).then(res => { return res.data })
}

export const deleteHPCSMasterDataAction = (data) => {
   return postRequest('deleteHPCSMasterData', data).then(res => { return res.data })
}

export const getICDMasterListAction = (data) => {
   return getRequest('getICD10MasterList', data).then(res => { return res.data })
}

export const addICDMasterAction = (data) => {
   return postRequest('addICDMaster', data).then(res => { return res.data })
}

export const deleteICDMasterDataAction = (data) => {
   return postRequest('deleteICDMasterData', data).then(res => { return res.data })
}

export const getMasterListAction = (data) => {
   return getRequest('getMasterList', data).then(res => { return res.data })
}

export const saveMasterDataAction = (data) => {
   return postRequest('saveMasterData', data).then(res => { return res.data })
}

export const getMasterTableDataAction = (data) => {
   return postRequest('getMasterTableDataForEdit', data).then(res => { return res.data })
}

export const deleteMasterDataAction = (data) => {
   return postRequest('deleteMasterData', data).then(res => { return res.data })
}

export const deleteMasterColumnAction = (data) => {
   return postRequest('deleteMasterColumn', data).then(res => { return res.data })
}

export const updateMasterTableAction = (data) => {
   return postRequest('updateMasterTable', data).then(res => { return res.data })
}

export const updateMasterTableFieldsAction = (data) => {
   return postRequest('updateMasterTableFields', data).then(res => { return res.data })
}

export const importExcelFileAction = (data) => {
   return postRequestFormData('importExcelFile', data).then(res => { return res.data })
}
export const getMasterDataDetailsAction = (data) => {
   return getRequest('getMasterDataDetails', data).then(res => { return res.data })
}
export const getMasterlistDetailByNameAction = (data) => {
   return postRequest('getMasterlistDetailByName', data).then(res => { return res.data })
}
export const getMasterTableColumnAction = (data) => {
   return postRequest('getMasterTableColumn', data).then(res => { return res.data })
}
export const addMasterDataDetailsAction = (data) => {
   return postRequest('addMasterDataDetails', data).then(res => { return res.data })
}
export const deleteTableDataAction = (data) => {
   return postRequest('deleteTableData', data).then(res => { return res.data })
}
export const getTableContentdetailByIdAction = (data) => {
   return postRequest('getTableContentdetailById', data).then(res => { return res.data })
}
export const updateTableContentDetailsByIdAction = (data) => {
   return postRequest('updateTableContentDetailsById', data).then(res => { return res.data })
}
export const getlogHistoryDetailsAction = (data) => {
   return postRequest('getLogHistory', data).then(res => { return res.data })
}

export const hendleSearchPatientAction = (data) => {
   return postRequest('searchPatientList', data).then(res => { return res.data })
}
export const getpatientDetailsById = (data) => {
   return postRequest('patientDetailsById', data).then(res => { return res.data })
}

export const getFormLogHistoryAction = (data) => {
   return postRequest('getFormLogHistory', data).then(res => { return res.data })
}

export const getDoctorList = (data) => {
   return getRequest('getDoctorsList', data).then(res => { return res.data })
}

export const addDoctorAppointment = (data) => {
   return postRequest('assignStaffToPatient', data).then(res => { return res.data })
}

export const getAppointmentList = (data) => {
   return getRequest('getAppointmentList', data).then(res => { return res.data })
}

export const getAppointmentsByDocId = (data) => {
   return postRequest('getAppointmentsByDocId', data).then(res => { return res.data })
}

export const updateAppointmentsById = (data) => {
   return postRequest('udateAppointment', data).then(res => { return res.data })
}

export const deleteAppointmentsById = (data) => {
   return postRequest('deleteAppointment', data).then(res => { return res.data })
}

export const patientStatusUpdateByIdAction = (data) => {
   return postRequest('patientStatusUpdate', data).then(res => { return res.data })
}
export const getPatiantUniqueIDAndMrnNumByIDAction = (data) => {
   return postRequest('getPatiantUniqueIDAndMrnNumByID', data).then(res => { return res.data })
}

export const getpatientFormDetailsById = (data) => {
   return postRequest('patientFormDetailsById', data).then(res => { return res.data })
}

export const getDraftDataAction = (data) => {
   return postRequest('getDraftData', data).then(res => { return res.data })
}

export const getStaffDutyAction = (data) => {
   return postRequest('getStaffDuty', data).then(res => { return res.data })
}

export const getFormDataByMasterAction = (data) => {
   return postRequest('getFormDataByMaster', data).then(res => { return res.data })
}

export const updateRecordStatus = (data) => {
   return postRequest('updateRecordStatus', data).then(res => { return res.data })
}