import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import "../componentCss/webform.css";
import { Formik, Field } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import Dashboardheader from "./dashboardheader";
import Sidebar from "./sidebar";
import "../componentCss/sidebar.css";
import toast, { Toaster } from 'react-hot-toast';
import "react-toastify/dist/ReactToastify.css";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import config from "../../config/config";
import { Link } from "react-router-dom";
import { editStaffSchema } from "../../validations/staffSchema";
import {
  updateStaffAction,
  getGroupTypesAction,
  getStaffDetailsAction,
} from "../../Action/admin.action";
import { decryptData } from './decrypt'
import Select from 'react-select';
import { BsEyeSlashFill,BsEyeFill} from "react-icons/bs";

const EditStaff = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [userGroup, setUserGroup] = useState([]);
  const [image_file, setimage_file] = useState("");
  const [imagePhotofile, setPhotofile] = useState("");
  const [imageSignature, setSignaturefile] = useState("");
  const [image_preview, setimage_preview] = useState("");
  const [imagePhotoPreview, setPhotopreview] = useState("");
  const [imageSignaturepreview, setSignaturepreview] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)

  const [staffDetails, setStaffDetails] = useState({
    namePrefix: "",
    firstName: "",
    lastName: "",
    middleName: "",
    email: "",
    password: "",
    confirmPassword: "",
    mobileNumber: "",
    address: "",
    city: "",
    state: "",
    designation: "",
    employeeId: "",
    emiratesIdNumber: "",
    licenceNumber: "",
    DateOfJoining: "",
    photo: "",
    signature: "",
    stampImage: "",
    gender: 0,
    userRoleId: [],
    status: 0,
  });
  const [isLaoding, setisLaoding] = useState(0);
      
      const [passwordData,setPasswordData] = useState(1)
      const [confirmPasswordData,setConfirmPasswordData] = useState(1)

      
  useEffect(() => {
    getGroupTypesAPI();
    // getStaffDetailsAPI();
  }, []);

  let getGroupTypesAPI = async (values) => {
    let res = await getGroupTypesAction(values);
    if (res.success) {
      let data = decryptData(res.data)
      const transformedData = data.map(item => ({
        value: item.id,
        label: item.name
      }));
      setUserGroup(transformedData);
    }
  };

  const [timeSlots, setTimeSlots] = useState({
    Sunday: { selected: false, from: null, to: null },
    Monday: { selected: false, from: null, to: null },
    Tuesday: { selected: false, from: null, to: null },
    Wednesday: { selected: false, from: null, to: null },
    Thursday: { selected: false, from: null, to: null },
    Friday: { selected: false, from: null, to: null },
    Saturday: { selected: false, from: null, to: null },
  });


  useEffect(() => {
    // Function to map the API response to the timeSlots state format
    const mapApiResponseToTimeSlots = (apiResponse) => {
      const updatedTimeSlots = { ...timeSlots };

      apiResponse.forEach(({ day, from, to }) => {
        updatedTimeSlots[day] = {
          selected: true,
          from: from.slice(0, 5), // Convert "09:35:00.000000" to "09:35"
          to: to.slice(0, 5), // Convert "18:34:00.000000" to "18:34"
        };
      });

      return updatedTimeSlots;
    };

    const getStaffDetailsAPI = async () => {
      let res = await getStaffDetailsAction({ id: id });
      if (res.success) {
        let data = decryptData(res.data);
        if (data.roleIds) {
          const reversedFieldsUserGroup = data.roleIds.map(item => {
            return {
              value: item.value,
              label: item.label, // Corrected "lable" to "label"
            };
          });
          data.userRoleId = reversedFieldsUserGroup;
        } else {
          data.userRoleId = [];
        }

        // Update time slots based on API response
        if (data.staffDutyIds && data.staffDutyIds.length > 0) {
          const preselectedTimeSlots = mapApiResponseToTimeSlots(data.staffDutyIds);
          setTimeSlots(preselectedTimeSlots);
        }

        setStaffDetails(data);
      }
    };

    getStaffDetailsAPI();
  }, [id]);

 


  let handleSubmit = async (values) => {
    setisLaoding(1);
    values.id = staffDetails?.id;



    // if (values.password && values.password.length > 0) {
    
    //     setPasswordError(true);
    //     setisLaoding(0);
    //     return;
    // }

    

    // Check for confirm password
    if (values.password && !values.confirmPassword) {
      setConfirmPasswordError(true);
      setPasswordError(false);
      setisLaoding(0);
      return;
    }

    if (!values.password && values.confirmPassword) {
      setPasswordError(true);
      setConfirmPasswordError(false);
      setisLaoding(0);
      return;
    }

    // Check if both passwords match
    if (values.password && values.confirmPassword) {
      if (values.password !== values.confirmPassword) {
        setPasswordError(true);
        setConfirmPasswordError(true);
        toast.error("Passwords must match.");
        setisLaoding(0);
        return;
      }
    }

    // Reset error states if validation passes
    setPasswordError(false);
    setConfirmPasswordError(false);

    values.userRolesIds = JSON.stringify(values.userRoleId)
    // Call the update function
    let res = await updateStaffAction(values);
    if (res.success) {
      toast.success(res.msg);
      setTimeout(() => {
        navigate(`${config.baseUrl}staff-management`);
      }, 2000);
    } else {
      setisLaoding(0);
      toast.error(res.msg);
    }
  };

  const handleChangeForstampImage = async (e, setFieldValue) => {
  
    e.preventDefault();
    let image_as_base64 = URL.createObjectURL(e.target.files[0]);
    let image_as_files = e.target.files[0];
  
    setFieldValue("stampImage", image_as_files);
    setimage_file(image_as_files);
    setimage_preview(image_as_base64);
  };

  const handleChangeForPhoto = async (e, setFieldValue) => {
  
    e.preventDefault();
    let image_as_base64 = URL.createObjectURL(e.target.files[0]);
    let image_as_files = e.target.files[0];
  
    setFieldValue("photo", image_as_files);
    setPhotofile(image_as_files);
    setPhotopreview(image_as_base64);
  };

  const handleChangeForSignature = async (e, setFieldValue) => {
  
    e.preventDefault();
    let image_as_base64 = URL.createObjectURL(e.target.files[0]);
    let image_as_files = e.target.files[0];
  
    setFieldValue("signature", image_as_files);
    setSignaturefile(image_as_files);
    setSignaturepreview(image_as_base64);
  };


  const passWordDisplay = async(e,data)=>{
    e.preventDefault()
    if(data == 1){
      setPasswordData(2)
    }
    else{
      setPasswordData(1)

    }
  }

  const confirmPassWordDisplay = async(e,data)=>{
    e.preventDefault()
    if(data == 1){
      setConfirmPasswordData(2)
    }
    else{
      setConfirmPasswordData(1)

    }
  }
  return (
    <>
      <Dashboardheader />
      <div className="wrapper">
        <Sidebar />
        <div id="content">
          <Container fluid className="p-4">
            <Row>
              <Col lg={12} md={12} sm={12} className="">
                <div className="innerhead mb-4">
                  <h5 className="headtitle">Edit User</h5>
                </div>
                <Link to={`${config.baseUrl}staff-management`}>
                  <span style={{ float: "right" }}>
                    Back <BsArrowLeftCircleFill />
                  </span>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} className="">
                <div className="formDiv">
                  <Formik
                    enableReinitialize={true}
                    initialValues={staffDetails}
                    validationSchema={editStaffSchema}
                    onSubmit={(values) => {
                      handleSubmit(values);
                    }}
                  >
                    {({
                      errors,
                      handleSubmit,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                      touched,
                      values,
                    }) => (
                      <Form onSubmit={handleSubmit}>

                        <Row className="mb-4">
                          <Form.Group as={Col}>
                            <Form.Label>Prefix</Form.Label>
                            <select name="namePrefix" className="form-control" onChange={handleChange} value={values.namePrefix}>
                              <option value="Mr"> Mr </option>
                              <option value="Ms"> Ms </option>
                              <option value="Miss"> Miss </option>
                              <option value="Mrs"> Mrs </option>
                              <option value="Master"> Master </option>
                              <option value="Fr"> Father (Fr) </option>
                              <option value="Rev"> Reverend (Rev) </option>
                              <option value="Dr"> Doctor (Dr) </option>
                              <option value="Atty"> Attorney (Atty) </option>
                              <option value="Hon"> Honorable (Hon) </option>
                              <option value="Prof"> Professor (Prof) </option>
                              <option value="Pres"> President (Pres) </option>
                              <option value="VP"> Vice President (VP) </option>
                              <option value="Gov"> Governor (Gov) </option>
                            </select>
                          </Form.Group>

                          <Form.Group as={Col}></Form.Group>
                        </Row>

                        <Row className="mb-4">
                          <Form.Group as={Col}>
                            <Form.Label>First name</Form.Label>
                            <input
                              className="form-control"
                              type="text"
                              name="firstName"
                              onChange={handleChange}
                              placeholder="Please enter first name"
                              onBlur={handleBlur}
                              value={values.firstName ? values.firstName : ""}
                            />

                            {errors.firstName && touched.firstName && (
                              <div className="text-danger">
                                {errors.firstName}
                              </div>
                            )}
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>Middle name</Form.Label>
                            <input
                              className="form-control"
                              type="text"
                              name="middleName"
                              onChange={handleChange}
                              placeholder="Please enter Middle Name"
                              onBlur={handleBlur}
                              value={values.middleName}
                            />

                          </Form.Group>
                        </Row>
                        <Row className="mb-4">
                          <Form.Group as={Col}>
                            <Form.Label>Last name</Form.Label>
                            <input
                              className="form-control"
                              type="text"
                              name="lastName"
                              onChange={handleChange}
                              value={values.lastName}
                              placeholder="Please enter last name"
                            />

                            {errors.lastName && touched.lastName && (
                              <div className="text-danger">
                                {errors.lastName}
                              </div>
                            )}
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>Employee ID</Form.Label>
                            <input
                              className="form-control"
                              type="text"
                              name="employeeId"
                              onChange={handleChange}
                              value={values.employeeId}
                              placeholder="Please enter Employee Id"
                            />

                            {errors.employeeId && touched.employeeId && (
                              <div className="text-danger">
                                {errors.employeeId}
                              </div>
                            )}
                          </Form.Group>
                        </Row>
                        <Row className="mb-4">
                          <Form.Group as={Col}>
                            <Form.Label>Email</Form.Label>
                            <input
                              className="form-control"
                              type="text"
                              name="email"
                              onChange={handleChange}
                              value={values.email}
                              placeholder="Please enter email"
                            />

                            {errors.email && touched.email && (
                              <div className="text-danger">{errors.email}</div>
                            )}
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>Mobile Number</Form.Label>
                            <input
                              className="form-control"
                              value={values.mobileNumber}
                              type="text"
                              name="mobileNumber"
                              onChange={handleChange}
                              placeholder="Ex. 91584758"
                              onKeyPress={(event) => {
                                if (!/^\d*[]?\d{0,1}$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />

                            {errors.mobileNumber && touched.mobileNumber && (
                              <div className="text-danger">
                                {errors.mobileNumber}
                              </div>
                            )}
                          </Form.Group>
                        </Row>

                        <Row className="mb-4">
                          <Form.Group as={Col}>
                            <Form.Label>Password
                               {/* <span style={{color:'red', fontSize:'12px'}}>If you do not wish to change your password, please leave this field blank.</span> */}
                               </Form.Label>
                            <div>
                              
                            <input
                              className="form-control"
                              autoComplete="off"
                              type={passwordData == 1 ?"password":"text"}
                              name="password"
                              onChange={handleChange}
                              value={values.password}
                              placeholder="Please enter password"
                            />
                            </div>
                            <div style={{position:"absolute",right:"43%",marginTop:"-33px"}}>
                              {passwordData == 1 ? 
                            <BsEyeSlashFill fill='#434544' size={20} onClick={(e)=>passWordDisplay(e,passwordData)}/>
                            :  
                            <BsEyeFill fill='#434544' size={20} onClick={(e)=>passWordDisplay(e,passwordData)} />
                            
                            }

                             
                             
                             
                             </div>
                           
                            {passwordError == true ?
                              <div className="text-danger">
                                Password is required
                              </div>
                              : ""}
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>Confirm Password</Form.Label>
                            <input
                              className="form-control"
                              type={confirmPasswordData == 1 ?"password":"text"}
                              name="confirmPassword"
                              value={values.confirmPassword}
                              onChange={handleChange}
                              placeholder="Please enter confirm password"
                            />
<div style={{position:"absolute",right:"3%",marginTop:"-33px"}}>
                              {confirmPasswordData == 1 ? 
                            <BsEyeSlashFill fill='#434544' size={20} onClick={(e)=>confirmPassWordDisplay(e,confirmPasswordData)}/>
                            :  
                            <BsEyeFill fill='#434544' size={20} onClick={(e)=>confirmPassWordDisplay(e,confirmPasswordData)} />
                            
                            }
                            </div>
                            {confirmPasswordError == true ? (
                              <div className="text-danger">
                                Confirm password is required
                              </div>
                            ) : ""}
                          </Form.Group>
                        </Row>

                        <Row className="mb-4">
                          <Form.Group as={Col}>
                            <Form.Label>City</Form.Label>
                            <input
                              className="form-control"
                              type="text"
                              name="city"
                              onChange={handleChange}
                              value={values.city}
                              placeholder="Please enter city"
                            />

                            {errors.city && touched.city && (
                              <div className="text-danger">{errors.city}</div>
                            )}
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>State</Form.Label>
                            <input
                              className="form-control"
                              type="text"
                              name="state"
                              onChange={handleChange}
                              value={values.state}
                              placeholder="Please enter state"
                            />

                            {errors.state && touched.state && (
                              <div className="text-danger">{errors.state}</div>
                            )}
                          </Form.Group>
                        </Row>

                        <Row className="mb-4">
                          <Form.Group as={Col}>
                            <Form.Label>Address</Form.Label>
                            <input
                              className="form-control"
                              type="text"
                              name="address"
                              onChange={handleChange}
                              value={values.address}
                              placeholder="Please enter address"
                            />

                            {errors.address && touched.address && (
                              <div className="text-danger">
                                {errors.address}
                              </div>
                            )}
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>Gender</Form.Label>
                            <select
                              name="gender"
                              value={values.gender}
                              className="form-control"
                              onChange={handleChange}
                            >
                              <option value={""}>Select gender</option>
                              <option value={1}>Male</option>
                              <option value={2}>Female</option>
                              <option value={3}>Other</option>
                            </select>

                            {errors.gender && touched.gender && (
                              <div className="text-danger">{errors.gender}</div>
                            )}
                          </Form.Group>
                        </Row>
                        <Row className="mb-4">
                          <Form.Group as={Col}>
                            <Form.Label>Emirates ID number</Form.Label>
                            <input
                              className="form-control"
                              type="text"
                              name="emiratesIdNumber"
                              onChange={handleChange}
                              value={values.emiratesIdNumber}
                              placeholder="Please enter emiratesIdNumber"
                            />

                            {errors.emiratesIdNumber &&
                              touched.emiratesIdNumber && (
                                <div className="text-danger">
                                  {errors.emiratesIdNumber}
                                </div>
                              )}
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>Designation</Form.Label>
                            <input
                              className="form-control"
                              type="text"
                              name="designation"
                              onChange={handleChange}
                              value={values.designation}
                              placeholder="Please enter designation"
                            />

                            {errors.designation && touched.designation && (
                              <div className="text-danger">
                                {errors.designation}
                              </div>
                            )}
                          </Form.Group>
                        </Row>
                        <Row className="mb-4">
                          <Form.Group as={Col}>
                            <Form.Label>Date of joining</Form.Label>
                            <input
                              className="form-control"
                              type="date"
                              name="DateOfJoining"
                              onChange={handleChange}
                              value={values.DateOfJoining}
                              placeholder="Please enter Date of Joining"
                            />

                            {errors.DateOfJoining && touched.DateOfJoining && (
                              <div className="text-danger">
                                {errors.DateOfJoining}
                              </div>
                            )}
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>Date of relieving</Form.Label>
                            <input
                              className="form-control"
                              type="date"
                              name="releavingDate"
                              onChange={handleChange}
                              value={values.releavingDate}
                              placeholder="Please enter Date of relieving"
                            />


                          </Form.Group>


                        </Row>

                        <Row className="mb-4">
                          <Form.Group as={Col}>
                            <Form.Label>License Number</Form.Label>
                            <input
                              className="form-control"
                              type="text"
                              name="licenceNumber"
                              onChange={handleChange}
                              placeholder="Please enter Licence Number "
                              value={values.licenceNumber}
                            />

                            {errors.licenceNumber && touched.licenceNumber && (
                              <div className="text-danger">
                                {errors.licenceNumber}
                              </div>
                            )}
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>Role</Form.Label>
                            <Field
                              name="userRoleId"
                              component={MultiSelectField}
                              options={userGroup}
                              staffDetails={staffDetails}
                            />
                            {errors.userRoleId && touched.userRoleId && (
                              <div className="text-danger">
                                {errors.userRoleId}
                              </div>
                            )}
                          </Form.Group>

                        </Row>
                        <Row className="mb-4">

                          <Form.Group as={Col}>
                            <Form.Label>Status</Form.Label>
                            <select
                              name="isActive"
                              value={values.isActive}
                              className="form-control"
                              onChange={handleChange}
                            >
                              <option value={1}>Active</option>
                              <option value={0}>Inactive</option>
                            </select>
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>Photo</Form.Label>
                            <input
                              className="form-control"
                              type="file"
                              name="photo"
                              accept="image/*"
                              onChange={(e) => handleChangeForPhoto(e, setFieldValue)}
                            />

                            {errors.photo && touched.photo && (
                              <div className="text-danger">{errors.photo}</div>
                            )}
                            {imagePhotoPreview ? (
                              <>
                                <img width="100" src={imagePhotoPreview}></img>
                                <br />
                              </>
                            ) : staffDetails.photo ? (
                              <>
                                <img
                                  width="100"
                                  src={staffDetails.photo}
                                ></img>
                                <br />
                              </>
                            ) : (
                              ""
                            )}
                          </Form.Group>


                        </Row>
                        <Row className="mb-4">
                          <Form.Group as={Col}>
                            <Form.Label>Signature</Form.Label>
                            <input
                              className="form-control"
                              type="file"
                              name="signature"
                              accept="image/*"
                              onChange={(e) => handleChangeForSignature(e, setFieldValue)}
                            />

                            {errors.signature && touched.signature && (
                              <div className="text-danger">
                                {errors.signature}
                              </div>
                            )}
                            {imageSignaturepreview ? (
                              <>
                                <img
                                  width="100"
                                  src={imageSignaturepreview}
                                ></img>
                                <br />
                              </>
                            ) : staffDetails.signature ? (
                              <>
                                <img
                                  width="100"
                                  src={staffDetails.signature}
                                ></img>
                                <br />
                              </>
                            ) : (
                              ""
                            )}
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>Stamp Image</Form.Label>
                            <input
                              className="form-control"
                              type="file"
                              name="stampImage"
                              accept="image/*"
                              onChange={(e) =>
                                handleChangeForstampImage(e, setFieldValue)
                              }
                            />

                            {errors.stampImage && touched.stampImage && (
                              <div className="text-danger">
                                {errors.stampImage}
                              </div>
                            )}


                            {image_preview ? (
                              <>
                                <img
                                  width="100"
                                  src={image_preview}
                                ></img>
                                <br />
                              </>
                            ) : staffDetails.stampImage ? (
                              <>
                                <img
                                  width="100"
                                  src={staffDetails.stampImage}
                                ></img>
                                <br />
                              </>
                            ) : (
                              ""
                            )}
                          </Form.Group>

                        </Row>

                        <br />

                        <Row className="mb-4">
                          <Form.Group as={Col}>
                            {isLaoding ? (
                              <>
                                <button
                                  disabled
                                  className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6"
                                >
                                  Loading{" "}
                                  <img
                                    height="20"
                                    width="20px"
                                    src="assets/images/loading.gif"
                                  />
                                </button>
                              </>
                            ) : (
                              <button
                                type="submit"
                                className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6"
                              >
                                Update
                              </button>
                            )}
                          </Form.Group>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

// Custom Select Component for Formik
const MultiSelectField = ({ options, field, form, staffDetails }) => {
  const onChange = (selectedOptions) => {
    form.setFieldValue(field.name, selectedOptions);
  };
  return (
    <Select
      isMulti
      name={field.name}
      value={field.value}
      options={options}
      onChange={onChange}
      onBlur={() => form.setFieldTouched(field.name, true)}
    />
  );
};

export default EditStaff;
