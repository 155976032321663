import { useState, forwardRef } from "react";
import { Table, Form, Row, Col, Button, FormControl, InputGroup } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt } from 'react-icons/fa';


const ReportFilter = ({ selectedReport, totalRows, formData, handleFilter, months, handleReset }) => {
    const [filters, setFilters] = useState({
        startMonth: new Date(),
        endMonth: new Date(),
        patient: '',
        
        staffName: '',
        billed: ''
    });

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isOpenCalender, setIsOpenCalender] = useState(false);



    const reportObj = {
        Home_Care: 'Home care',
        All_Patient: 'All Patient',
        Medical_Report: 'Medical Report',
        Patient_Admitted: 'Patient Admitted',
        Discharged_Patients: 'Discharged Patients',
        Rehab_Reports: 'Rehab Reports',
        Complication_Reports: 'Complication Reports',
        Dialysis_Reports: 'Dialysis Reports'
    };


    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const handleFilterChange = (e) => {
      
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const handleFilterMonth = (name, date) => {
     setFilters({ ...filters, [name]: date });
    }

    const handleCalendarOpen = () => {
        setIsOpenCalender(!isOpenCalender)
    }


    return (
        <div className='maintable table-responsive'>
            <Row>
                <Col>
                    <Form.Label style={{ fontSize: '24px' }}>Filters By:</Form.Label>
                </Col>
            </Row>
            {selectedReport.title === reportObj.Dialysis_Reports ? (
                <Row className="mb-3">
                   <Col>
                    <Form.Group controlId="filterStartMonth">
                        <Form.Label>From Month</Form.Label>
                        <DatePicker
                            selected={filters.startMonth}
                            onChange={(date) => handleFilterMonth('startMonth', date)}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            placeholderText="Select start month"
                            className="form-control"
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="filterEndMonth">
                        <Form.Label>To Month</Form.Label>
                        <DatePicker
                            selected={filters.endMonth}
                            onChange={(date) => handleFilterMonth('endMonth', date)}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            placeholderText="Select end month"
                            className="form-control"
                            minDate={filters.startMonth} // Ensure end date is after start date
                        />
                    </Form.Group>
                </Col>
                    <Col>
                        <Form.Group controlId="filterPatient">
                            <Form.Label>Patient MRN</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter patient MRN"
                                name="patient"
                                value={filters.patient}
                                onChange={handleFilterChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="filterDate">
                            <Form.Label>Date</Form.Label>
                      
                            <div className="d-flex">
                            <DatePicker
                            placeholderText="Select Date Range"
                                className="form-control"
                                selected={startDate}
                                onChange={handleDateChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                inline={isOpenCalender}
                                open={isOpenCalender}
                                onClickOutside={() => setIsOpenCalender(false)}
                            />
                            <FaCalendarAlt style={{position:'relative', top:'10px', left:'10px'}} onClick={handleCalendarOpen} />

                            </div>

                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="filterStaff">
                            <Form.Label>Staff</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter staff"
                                name="staffName"
                                value={filters.staffName}
                                onChange={handleFilterChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="filterBilled">
                            <Form.Label>Billed/Unbilled</Form.Label>
                            <Form.Control
                                as="select"
                                name="billed"
                                value={filters.billed}
                                onChange={handleFilterChange}
                            >
                                <option value="">Select</option>
                                <option value="billed">Billed</option>
                                <option value="unbilled">Unbilled</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
            ) : selectedReport.title === reportObj.Home_Care ? (
                <Row className="mb-3">
                  
                  <Col>
                    <Form.Group controlId="filterStartMonth">
                        <Form.Label>From Month</Form.Label>
                        <DatePicker
                            selected={filters.startMonth}
                            onChange={(date) => handleFilterMonth('startMonth', date)}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            placeholderText="Select start month"
                            className="form-control"
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="filterEndMonth">
                        <Form.Label>To Month</Form.Label>
                        <DatePicker
                            selected={filters.endMonth}
                            onChange={(date) => handleFilterMonth('endMonth', date)}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            placeholderText="Select end month"
                            className="form-control"
                            minDate={filters.startMonth} // Ensure end date is after start date
                        />
                    </Form.Group>
                </Col>
                    <Col>
                        <Form.Group controlId="filterPatient">
                            <Form.Label>Patient MRN</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Patient MRN"
                                name="patient"
                                value={filters.patient}
                                onChange={handleFilterChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="filterDate">
                            <Form.Label>Date</Form.Label>
                            <div className="d-flex">
                            <DatePicker
                            placeholderText="Select Date Range"
                                className="form-control"
                                selected={startDate}
                                onChange={handleDateChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                inline={isOpenCalender}
                                open={isOpenCalender}
                                onClickOutside={() => setIsOpenCalender(false)}
                            />
                            <FaCalendarAlt style={{position:'relative', top:'10px', left:'10px'}} onClick={handleCalendarOpen} />

                            </div>
                            
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="filterLevel">
                            <Form.Label>Level</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Level"
                                name="level"
                                value={filters.level}
                                onChange={handleFilterChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="filterStaff">
                            <Form.Label>Staff</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter staff"
                                name="staffName"
                                value={filters.staffName}
                                onChange={handleFilterChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="filterBilled">
                            <Form.Label>Billed/Unbilled</Form.Label>
                            <Form.Control
                                as="select"
                                name="billed"
                                value={filters.billed}
                                onChange={handleFilterChange}
                            >
                                <option value="">Select</option>
                                <option value="billed">Billed</option>
                                <option value="unbilled">Unbilled</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
            ) : selectedReport.title === reportObj.Rehab_Reports ? (
                <Row className="mb-3">
                   <Col>
                    <Form.Group controlId="filterStartMonth">
                        <Form.Label>From Month</Form.Label>
                        <DatePicker
                            selected={filters.startMonth}
                            onChange={(date) => handleFilterMonth('startMonth', date)}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            placeholderText="Select start month"
                            className="form-control"
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="filterEndMonth">
                        <Form.Label>To Month</Form.Label>
                        <DatePicker
                            selected={filters.endMonth}
                            onChange={(date) => handleFilterMonth('endMonth', date)}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            placeholderText="Select end month"
                            className="form-control"
                            minDate={filters.startMonth} // Ensure end date is after start date
                        />
                    </Form.Group>
                </Col>
                    <Col>
                        <Form.Group controlId="filterPatient">
                            <Form.Label>Patient MRN</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Patient MRN"
                                name="patient"
                                value={filters.patient}
                                onChange={handleFilterChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="filterDoctor">
                            <Form.Label>Doctors</Form.Label>
                            <select className="form-control" name="doctor" value={filters.doctor} onChange={handleFilterChange}>
                                <option value="">Select Doctor</option>
                                {/* Add doctor options here */}
                            </select>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="filterBilled">
                            <Form.Label>Billed/Unbilled</Form.Label>
                            <Form.Control
                                as="select"
                                name="billed"
                                value={filters.billed}
                                onChange={handleFilterChange}
                            >
                                <option value="">Select</option>
                                <option value="billed">Billed</option>
                                <option value="unbilled">Unbilled</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
            ) : ''}

            <Row>
                <Col className="d-flex">
                    <div className="">
                        <Button className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6 mt-4" onClick={() => handleFilter(filters, startDate, endDate)}>Search</Button>
                    </div>
                    &nbsp;
                    <div >
                        <Button className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6 mt-4" onClick={() => handleReset()}>Reset</Button>
                    </div>
                </Col>

            </Row>
        </div>
    );
};

export default ReportFilter;
