// decrypt.js
import CryptoJS from 'crypto-js';
let secretKey = process.env.REACT_APP_SECRET_KEY_ENCRYPTION

export const decryptData = (encryptedData) => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  if(originalText){
    return JSON.parse(originalText);
  }else{
    return []
  }
};
