import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import "../componentCss/webform.css";
import { Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import Dashboardheader from "./dashboardheader";
import Sidebar from "./sidebar";
import "../componentCss/sidebar.css";
// import { ToastContainer, toast } from "react-toastify";
import toast, { Toaster } from 'react-hot-toast';

import "react-toastify/dist/ReactToastify.css";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import config from "../../config/config";
import { Link } from "react-router-dom";
import {
  profileSchema,
  editPasswordSchema,
} from "../../validations/staffSchema";
import {
  updateStaffAction,
  getStaffDetailsAction,
  changePasswordAction,
} from "../../Action/admin.action";
import Cookies from "js-cookie";
let loginData = !Cookies.get("loginSuccessNeurulaHealth")
  ? []
  : JSON.parse(Cookies.get("loginSuccessNeurulaHealth"));

const ChangePassword = () => {
  const navigate = useNavigate();
  const [staffDetails, setStaffDetails] = useState({
    oldPassword: "",
    newPassword: "",
  });
  const [isLaoding, setisLaoding] = useState(0);

  let handleSubmit = async (values) => {
    setisLaoding(1);
    let res = await changePasswordAction(values);
    if (res.success) {
      setisLaoding(0);
      toast.success(res.msg);
    } else {
      setisLaoding(0);
      toast.error(res.msg);
    }
  };

  return (
    <>
      {/* <Dashboardheader /> */}
      {/* <Toaster /> */}
      {/* <div className="wrapper"> */}
        {/* <Sidebar /> */}
        {/* <div id="content"> */}
          <Container fluid className="p-4">
            <Row>
              <Col lg={12} md={12} sm={12} className="">
                <div className="innerhead mb-4">
                  <h5 className="headtitle">Change Password</h5>
                </div>
                <Link to={`${config.baseUrl}dashboard`}>
                  <span style={{ float: "right" }}>
                    Back <BsArrowLeftCircleFill />
                  </span>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} className="">
                <div className="formDiv">
                  <Formik
                    enableReinitialize={true}
                    initialValues={staffDetails}
                    validationSchema={editPasswordSchema}
                    onSubmit={(values) => {
                      handleSubmit(values);
                    }}
                  >
                    {({
                      errors,
                      handleSubmit,
                      handleBlur,
                      handleChange,
                      touched,
                      values,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <Row className="mb-4">
                          <Form.Group as={Col}></Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>Old Password</Form.Label>
                            <input
                              className="form-control"
                              type="text"
                              name="oldPassword"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={
                                values.oldPassword ? values.oldPassword : ""
                              }
                            />

                            {errors.oldPassword && touched.oldPassword && (
                              <div className="text-danger">
                                {errors.oldPassword}
                              </div>
                            )}
                          </Form.Group>

                          <Form.Group as={Col}></Form.Group>
                        </Row>

                        <Row>
                          <Form.Group as={Col}></Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>New Password</Form.Label>
                            <input
                              className="form-control"
                              type="text"
                              name="newPassword"
                              onChange={handleChange}
                              value={values.newPassword}
                            />

                            {errors.newPassword && touched.newPassword && (
                              <div className="text-danger">
                                {errors.newPassword}
                              </div>
                            )}
                          </Form.Group>
                          <Form.Group as={Col}></Form.Group>
                        </Row>
                        <Row>
                          <Form.Group as={Col}></Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>Confirm Password</Form.Label>
                            <input
                              className="form-control"
                              type="text"
                              name="confirmPassword"
                              onChange={handleChange}
                              value={values.confirmPassword}
                            />

                            {errors.confirmPassword &&
                              touched.confirmPassword && (
                                <div className="text-danger">
                                  {errors.confirmPassword}
                                </div>
                              )}
                            <br />
                            <button
                              type="submit"
                              className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6"
                            >
                              Update
                            </button>
                          </Form.Group>

                          <Form.Group as={Col}></Form.Group>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </Container>
        {/* </div> */}
      {/* </div> */}
    </>
  );
};

export default ChangePassword;
