import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import "../componentCss/webform.css";
import { Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import Dashboardheader from "./dashboardheader";
import Sidebar from "./sidebar";
import "../componentCss/sidebar.css";
import toast, { Toaster } from 'react-hot-toast';
import "react-toastify/dist/ReactToastify.css";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import config from "../../config/config";
import { Link } from "react-router-dom";
import { editPatientSchema } from "../../validations/patientSchema";
import {
  updatePatientCredentialsAction,
  getGroupTypesAction,
  getpatientDetailsbyIdAction,
} from "../../Action/admin.action";
import {decryptData} from './decrypt'

const EditPatientCredintial = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [patientDetails, setpatientDetails] = useState({
    username: "",
    password: "",
    confirmPassword: "",
    MRNno: "",
  });
  const [isLaoding, setisLaoding] = useState(0);

  useEffect(() => {
    getpatientDetailsAPI();
  }, []);


  let getpatientDetailsAPI = async () => {
    let res = await getpatientDetailsbyIdAction({ MRNno: id });
    if (res.success) {
      setpatientDetails(decryptData(res.data));
    }
  };

  let handleSubmit = async (values) => {
    setisLaoding(1);
    values.id = patientDetails?.id;
    let res = await updatePatientCredentialsAction(values);
    if (res.success) {
      setisLaoding(0);
      toast.success(res.msg);
      setTimeout(() => {
        navigate(`${config.baseUrl}patientCredentials`);
      }, 2000);
      setpatientDetails({
        username: "",
        password: "",
        confirmPassword: "",
        MRNno: "",
      })
    } else {
      setisLaoding(0);
      toast.error(res.msg);
    }
  };

  return (
    <>
      <Dashboardheader />
      <div className="wrapper">
        <Sidebar />
        <div id="content">
          <Container fluid className="p-4">
            <Row>
              <Col lg={12} md={12} sm={12} className="">
                <div className="innerhead mb-4">
                  <h5 className="headtitle">Edit Patient Credential</h5>
                </div>
                <Link to={`${config.baseUrl}patientCredentials`}>
                  <span style={{ float: "right" }}>
                    Back <BsArrowLeftCircleFill />
                  </span>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} className="">
                <div className="formDiv">
                <Formik
                   enableReinitialize={true}
                    initialValues={patientDetails}
                    validationSchema={editPatientSchema}
                    onSubmit={(values) => {
                      handleSubmit(values);
                    }}
                  >
                    {({
                      errors,
                      handleSubmit,
                      handleBlur,
                      handleChange,
                      touched,
                      setFieldValue,
                      values,
                    }) => (
                      <Form onSubmit={handleSubmit} autocomplete="off">
                        <Row className="mb-4">
                          <Form.Group as={Col}>
                            <Form.Label>User name</Form.Label>
                            <input
                              className="form-control"
                              type="text"
                              name="username"
                              onChange={handleChange}
                              value={values.username}
                              placeholder="Please enter User name"
                            />

                            {errors.username && touched.username && (
                              <div className="text-danger">
                                {errors.username}
                              </div>
                            )}
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>MRN No.</Form.Label>
                            <input
                              className="form-control"
                              type="text"
                              disabled
                              name="MRNno"
                              onChange={handleChange}
                              value={values.MRNno}
                              placeholder="Please enter Unique MRN No"
                              autocomplete="off"
                            />

                            {errors.MRNno && touched.MRNno && (
                              <div className="text-danger">{errors.MRNno}</div>
                            )}
                          </Form.Group>
                        </Row>

                        <Row className="mb-4">
                          <Form.Group as={Col}>
                            <Form.Label>Password</Form.Label>
                            <input
                              className="form-control"
                              type="password"
                              name="password"
                              onChange={handleChange}
                              value={values.password}
                              placeholder="Please enter password"
                            />

                            {errors.password && touched.password && (
                              <div className="text-danger">
                                {errors.password}
                              </div>
                            )}
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>Confirm Password</Form.Label>
                            <input
                              className="form-control"
                              type="password"
                              name="confirmPassword"
                              value={values.confirmPassword}
                              onChange={handleChange}
                              placeholder="Please enter confirm password"
                            />

                            {errors.confirmPassword &&
                              touched.confirmPassword && (
                                <div className="text-danger">
                                  {errors.confirmPassword}
                                </div>
                              )}
                          </Form.Group>
                        </Row>

                        <Row className="mb-4">
                          <Form.Group as={Col}>
                            {isLaoding ? (
                              <>
                                <button
                                  disabled
                                  className="btn-sm btn btn-primary"
                                >
                                  Loading{" "}
                                  <img
                                    height="20"
                                    width="20px"
                                    src="assets/images/loading.gif"
                                  />
                                </button>
                              </>
                            ) : (
                              <button
                                type="submit"
                                className="btn-sm btn btn-primary"
                              >
                                Submit
                              </button>
                            )}
                          </Form.Group>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default EditPatientCredintial;
