import { useEffect, useState } from "react";
import { Container, Row, Col, Button, Dropdown, Table } from "react-bootstrap";
import ReportsModal from "../ModalComponents/ReportsModal";
import { useDispatch, useSelector } from "react-redux";
import { addReports, getReports } from "../../../redux/slices/reportsSlice";
import toast from "react-hot-toast";
import { getFormDataListAction, getFormListAction } from "../../../Action/admin.action";
import ReportsTable from "./ReportsTable";
import { decryptData } from '../decrypt';

const ReportsList = () => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [reportsData, setReportData] = useState([]);
    const [formFields, setFormFields] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [formList, setFormList] = useState([]);
    const [loading, setLoading] = useState(false);

    const [selectedReport, setSelectedReport] = useState(null);
    const [formData, setFormData] = useState([]);
    const reportsList = useSelector((state) => state.reports?.getReportsData);

    useEffect(() => {
        if (reportsList && reportsList.data) {
            setReportData(reportsList.data);
            if (reportsList.data.length > 0) {
                setSelectedReport(reportsList.data[0]); // Set the first report as the default selected
            }
        } else {
            getReport();
        }
    }, [reportsList]);

    useEffect(() => {
        getReport();
        getFormListAPI();
        getFormDataListAPI(44);
    }, []);

    const getFormDataListAPI = async (id) => {
        setLoading(true);
        let res = await getFormDataListAction({ id });
        if (res.success) {
            setFormFields(decryptData(res.data?.fields));
            setFormData(decryptData(res.data?.records));
            setTotalRows(res.data.totalRows);
        }else{
            setFormFields([])
            setFormData([])
            setTotalRows(0)
        }
        setLoading(false);
    };

    const getFormListAPI = async () => {
        let res = await getFormListAction();
        if (res.success) {
            setFormList(decryptData(res.data));
        }else{
            setFormList([]);
        }
    };

    const getReport = async () => {
        let res = await dispatch(getReports());
        if (res.payload?.success) {
            let data = decryptData(res.payload.data)
            setReportData(data);
            if (data.length > 0) {
                setSelectedReport(data[0]); // Set the first report as the default selected
            }
        }
    };

    const handleShow = () => {
        setShow(true);
    };

    const handleClose = () => {
        setShow(!show);
    };

    const handleSubmit = async (title, formId) => {
        let obj = { title, formId };
        let res = await dispatch(addReports(obj));
        if (res && res.payload && res.payload.success) {
            setShow(!show);
            toast.success(res.payload.msg);
        }
    };

    const handleSelectReport = (report) => {
        setSelectedReport(report);
        getFormDataListAPI(report.formId)
    };

    return (
        <>
            {loading ? (
                <>Loading.....</>
            ) : (
                <div id="content">
                    <Container fluid className="p-4">
                        <Row>
                            <Col lg={10} md={10} sm={10} className="d-flex justify-content-between align-items-center">
                                <div className="innerhead mb-4">
                                    <h5 className="headtitle">Reports</h5>
                                </div>
                                <div onClick={handleShow}>
                                    <Button>Add+</Button>
                                </div>
                            </Col>
                        </Row>

                        <Row className="align-items-center mb-3">
                            <Col lg={2} md={3} sm={4}>
                                <div>Select Report:</div>
                            </Col>
                            <Col lg={8} md={7} sm={6}>
                                <Dropdown onSelect={(e) => handleSelectReport(reportsData.find(report => report.formId.toString() === e))}>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        {selectedReport ? selectedReport.title : "Select Report"}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {reportsData.map((report) => (
                                            <Dropdown.Item key={report.formId} eventKey={report.formId.toString()}>
                                                {report.title}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12} md={12} sm={12} className="">
                                <ReportsTable selectedReport={selectedReport} formData={formData} formFields={formFields} totalRows={totalRows} setFormData={setFormData}/>
                            </Col>
                        </Row>
                    </Container>
                </div>
            )}
            {show && <ReportsModal show={show} handleClose={handleClose} handleSubmit={handleSubmit} formList={formList}/>}
        </>
    );
};

export default ReportsList;
